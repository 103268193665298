import * as THREE from "three";

import { useEffect } from "react";
import { Nameable } from "./types";

export type { Nameable } from "./types";

export function useName(obj: THREE.Object3D, { id, tags }: Nameable) {
  useEffect(() => {
    if (id) {
      obj.name = id;
      obj.userData.id = id;
    }

    return () => {
      if (obj.userData.id) {
        obj.userData.id = undefined;
      }
    };
  }, [obj, obj.userData, id]);

  useEffect(() => {
    if (tags) {
      obj.userData.tags = tags;
    }
    return () => {
      if (obj.userData.tags) {
        obj.userData.tags = undefined;
      }
    };
  }, [obj.userData, tags]);
}
