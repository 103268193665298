import { Category, SubCategory } from "./types";

import SocialIdentitiesIcon from "~/assets/SocialIdentities.svg";
import FamilyAndPetsIcon from "~/assets/FamilyAndPets.svg";
import PersonalityAndInterestsIcon from "~/assets/PersonalityAndInterests.svg";
import ProfessionalIcon from "~/assets/Professional.svg";

export function getAvailableCategories(): Category[] {
  return Categories;
}

export function getAvailableSubcategories(): SubCategory[] {
  return getAvailableCategories().flatMap((c) => c.subCategories);
}

export const Categories: Category[] = [
  {
    name: "Social Identities",
    icon: SocialIdentitiesIcon,
    subCategories: [
      {
        name: "Race/Ethnicity",
        attributes: [
          { name: "Black or African American", communityId: 1 },
          { name: "White" },
          { name: "Asian" },
          { name: "South Asian", communityId: 8 },
          { name: "East Asian", communityId: 2 },
          { name: "Southeast Asian", communityId: 2 },
          { name: "Other Asian Origin" },
          { name: "Hispanic or Latino/a/e/x", communityId: 3 },
          {
            name: "Middle Eastern, North African, Near Eastern",
            communityId: 5,
          },
          {
            name: "Native American, American Indian, Alaska Native, or First Nations",
            communityId: 6,
          },
          { name: "Indigenous Mexican or Central American", communityId: 6 },
          { name: "Indigenous South American", communityId: 6 },
          { name: "Native Hawaiian", communityId: 6 },
          { name: "Pacific Islander", communityId: 6 },
          { name: "Immigrant" },
          { name: "First-Generation American" },
          { name: "Other" },
        ],
      },
      {
        name: "Gender",
        attributes: [
          { name: "Agender", communityId: 4 },
          { name: "Man" },
          { name: "Nonbinary", communityId: 4 },
          { name: "Woman", communityId: 10 },
          { name: "Cisgender" },
          { name: "Gender Nonconforming", communityId: 4 },
          { name: "Transgender", communityId: 4 },
          { name: "Other", communityId: 4 },
        ],
      },
      {
        name: "Marital Status",
        attributes: [
          { name: "Single" },
          { name: "Married" },
          { name: "Cohabitating" },
          { name: "Divorced" },
          { name: "Widowed" },
          { name: "Domestic Partnership" },
          { name: "In a Relationship" },
          { name: "Other" },
        ],
      },
      {
        name: "Disabilities",
        attributes: [
          { name: "Chronic Illness", communityId: 7 },
          { name: "Communication", communityId: 7 },
          { name: "Developmental", communityId: 7 },
          { name: "Hearing", communityId: 7 },
          { name: "Intellectual", communityId: 7 },
          { name: "Learning", communityId: 7 },
          { name: "Mental Health", communityId: 7 },
          { name: "Neurological", communityId: 7 },
          { name: "Mobility", communityId: 7 },
          { name: "Vision", communityId: 7 },
          { name: "Other", communityId: 7 },
        ],
      },

      {
        name: "Sexual Orientation",
        attributes: [
          { name: "Heterosexual" },
          { name: "Gay", communityId: 4 },
          { name: "Lesbian", communityId: 4 },
          { name: "Bisexual", communityId: 4 },
          { name: "Pansexual", communityId: 4 },
          { name: "Asexual", communityId: 4 },
          { name: "Queer", communityId: 4 },
          { name: "Demisexual", communityId: 4 },
          { name: "Questioning", communityId: 4 },
          { name: "Other", communityId: 4 },
        ],
      },

      {
        name: "Military",
        attributes: [
          { name: "Military Spouse", communityId: 9 },
          { name: "Other", communityId: 9 },
        ],
        multiselectMatrix: {
          options: [
            [
              "Air Force",
              "Army",
              "Marines",
              "Navy",
              "Space Force",
              "Coast Guard",
              "National Guard",
            ],
            ["Active Duty", "Reservist", "Member", "Veteran"],
          ],
          communityId: 9,
        },
      },
    ],
  },
  {
    name: "Family & Pets",
    icon: FamilyAndPetsIcon,
    subCategories: [
      {
        name: "Familial Relationships",
        attributes: [
          { name: "Parent" },
          { name: "Adult Dependent Caregiver" },
          { name: "Single Parent" },
          { name: "Grandparent" },
          { name: "Only Child" },
          { name: "Oldest Child" },
          { name: "Middle Child" },
          { name: "Youngest Child" },
          { name: "Sibling" },
          { name: "Caregiver" },
          { name: "Godparent" },
          { name: "Aunt" },
          { name: "Uncle" },
          { name: "Adopted" },
          { name: "Guardian" },
          { name: "Other" },
        ],
      },
      {
        name: "Pets",
        attributes: [
          { name: "Dog" },
          { name: "Cat" },
          { name: "Fish" },
          { name: "Bird" },
          { name: "Other" },
        ],
      },
    ],
  },
  {
    name: "Personality & Interests",
    icon: PersonalityAndInterestsIcon,
    subCategories: [
      {
        name: "Traits",
        attributes: [
          { name: "Extrovert" },
          { name: "Introvert" },
          { name: "Ambivert" },
          { name: "Optimist" },
          { name: "Jokester" },
          { name: "Advocate" },
          { name: "Deep Thinker" },
          { name: "Other" },
        ],
      },
      {
        name: "Hobbies",
        attributes: [
          { name: "Foodie" },
          { name: "Concertgoer" },
          { name: "Sneakerhead" },
          { name: "Fashion Lover" },
          { name: "World Traveler" },
          { name: "Artist" },
          { name: "Yogi" },
          { name: "Athlete" },
          { name: "Musician" },
          { name: "Teacher" },
          { name: "Dancer" },
          { name: "Bookworm" },
          { name: "Activist" },
          { name: "Crafter" },
          { name: "Fitness fanatic" },
          { name: "Other" },
        ],
      },
      {
        name: "Sports",
        attributes: [
          { name: "Soccer/Futbol" },
          { name: "American Football" },
          { name: "Tennis" },
          { name: "Basketball" },
          { name: "Running" },
          { name: "Gymnastics" },
          { name: "Lacrosse" },
          { name: "Dance" },
          { name: "Baseball" },
          { name: "Volleyball" },
          { name: "Boxing" },
          { name: "Rugby" },
          { name: "Hockey" },
          { name: "Bowling" },
          { name: "Rowing" },
          { name: "Swimming" },
          { name: "Surfing" },
          { name: "Cycling" },
          { name: "Wrestling" },
          { name: "Pickleball" },
          { name: "Skiing" },
          { name: "Triathalon" },
          { name: "Softball" },
          { name: "Cricket" },
          { name: "Other" },
        ],
      },
    ],
  },
  {
    name: "Professional",
    icon: ProfessionalIcon,
    subCategories: [
      {
        name: "Education",
        attributes: [
          { name: "High School/GED" },
          { name: "Bachelor's" },
          { name: "Master's" },
          { name: "Doctorate" },
          { name: "Other" },
        ],
      },
      {
        name: "School",
        attributes: [
          { name: "Community College" },
          { name: "Public University" },
          { name: "Private University" },
          { name: "Online University" },
          { name: "Certificate Program" },
          { name: "First-Generation College Graduate" },
          { name: "Other" },
        ],
      },
      {
        name: "Career Level",
        internalOnly: true,
        attributes: [
          { name: "Intern" },
          { name: "Client Service - Other" },
          { name: "Jr. Staff or Equivalent" },
          { name: "Staff or Equivalent" },
          { name: "Senior or Equivalent" },
          { name: "Manager or Equivalent" },
          { name: "Senior Manager or Equivalent" },
          { name: "Managing Director" },
          { name: "Partner or Principal" },
          { name: "Other" },
        ],
      },
      {
        name: "Career Level",
        externalOnly: true,
        attributes: [
          { name: "Entry-Level" },
          { name: "Mid-Level" },
          { name: "Advanced" },
          { name: "Other" },
        ],
      },
    ],
  },
];

// Subcategories with multiselect options.
// Useful to reference for some conditional rendering
export const MultiSelectSubcategories = getAvailableSubcategories()
  .filter((sc) => !!sc.multiselectMatrix)
  .map((sc) => sc.name);

export const findCategoryForSubcategory = (
  subCategory: string
): Category | undefined => {
  return getAvailableCategories().find(
    (c) => !!c.subCategories.find((sc) => sc.name === subCategory)
  );
};
