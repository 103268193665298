export interface UserAttribute {
  attribute: Attribute;
  subCategory: string;
  intensity: number;
}
export interface UserIdentity {
  attributes: UserAttribute[];
  employmentStatus: "internal" | "external" | undefined;
}

export interface MultiSelectAttributeMatrix {
  options: string[][];
  communityId?: number;
}

export interface Attribute {
  name: string;
  communityId?: number;
}

export interface SubCategory {
  name: string;
  attributes: Attribute[];
  multiselectMatrix?: MultiSelectAttributeMatrix;
  internalOnly?: boolean;
  externalOnly?: boolean;
}

export interface Category {
  name: string;
  subCategories: SubCategory[];
  icon: string;
}

export const enum ResourceType {
  External,
  ExternalPrimary,
  Internal,
  InternalPrimary,
  Community
}

export interface Resource {
  label: string;
  link: string;
  type: ResourceType;
  group?: number;
}
