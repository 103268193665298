import { useEffect } from "react";
import { buildRoute } from "~/config";
import { Box, Link } from "../ui";

export function HomeScreen() {
  const search = new URLSearchParams(window.location.search);
  const throwError = search.get("testException") === "true2";
  useEffect(() => {
    if (throwError) {
      throw new Error("Sample Exception");
    }
  }, [throwError]);

  return (
    <div>
      <p>Deloitte University DEI: Home</p>
      <Box display="flex" flexDirection="column" pl={4}>
        <Box py={3}>
          <Link to={buildRoute("/large")} label="Large Screen" />
        </Box>
        <Box py={3}>
          <Link to={buildRoute("/touch")} label="Touch Screen" />
        </Box>
      </Box>
    </div>
  );
}
