import { Box, Button, Text } from "~/ui";
import { ScreenContainer } from "~/ui/screen";
import { Header } from "./Header";
import { useIdentityContext } from "./hooks";

export function InternalExternalSelectionScreen() {
  const context = useIdentityContext();
  const handleClick = (value: "internal" | "external") => {
    context.handleEmploymentStatusSelection(value);
    context.setScreenState("CategorySelection");
  };

  return (
    <ScreenContainer>
      <Header />
      <Text
        variant="body1"
        style={{
          fontSize: "34px",
        }}
      >
        Before we begin, are you a Deloitte professional or external to
        Deloitte?
        <br />
        (Your answer to this question will help us curate resources for you.)
      </Text>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pt={4}
        style={{ width: 785, height: 360, margin: "0 auto" }}
      >
        <Button onClick={() => handleClick("internal")}>
          <Text variant="h2">DELOITTE PROFESSIONAL</Text>
        </Button>
        <Button onClick={() => handleClick("external")}>
          <Text variant="h2">EXTERNAL TO DELOITTE</Text>
        </Button>
      </Box>
    </ScreenContainer>
  );
}
