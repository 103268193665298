import { ReactNode } from "react";
import { Scene, XYZ } from "~/gamehook";

import { CameraProps } from "~/gamehook/camera";

interface Props {
  children: ReactNode;
  small?: boolean;
}
interface CameraSettings {
  position: XYZ | undefined;
  orientation: XYZ | undefined;
}

const USE_SIDE_VIEW = false;

const search = new URLSearchParams(window.location.search);
const cameraX = parseFloat(search.get("cameraX") ?? "0");
const cameraY = parseFloat(search.get("cameraY") ?? "0");
const cameraZ = parseFloat(search.get("cameraZ") ?? "0");
const colorString = search.get("background") ?? "353333";
const backgroundColor = `#${colorString}`;

// Resources page has a different view of the scene from the large screen.
// This appears in a smaller window. To make sure the objects are in view,
// we zoom out the camera much more
const HEAD_ON: CameraSettings = {
  position: [cameraX, cameraY, cameraZ],
  orientation: undefined,
};
const SIDE_VIEW: CameraSettings = {
  position: [9, 0, -2.5],
  orientation: [0, 1.4, 0],
};
const { position } = USE_SIDE_VIEW ? SIDE_VIEW : HEAD_ON;

const SMALL_WIDTH = 300;
const SMALL_HEIGHT = 300;
const DELIVERABLE_WIDTH = 1920;
const DELIVERABLE_HEIGHT = 1080;

const CameraParams: CameraProps = {
  variant: "perspective",
  position,
};
if (window.location.pathname !== "/large") {
  CameraParams["width"] = DELIVERABLE_WIDTH;
  CameraParams["height"] = DELIVERABLE_HEIGHT;
}

export function KaleidoscopeScene({ children, small }: Props) {
  if (small) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            borderRadius: 150,
            marginBottom: 32,
            border: "5px #86BD40 solid",
            // zIndex value prevents canvas from spilling over border radius
            // outline on mobile
            zIndex: 1,
            height: SMALL_HEIGHT,
            width: SMALL_WIDTH,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor,
            overflow: "hidden",
          }}
        >
          <div style={{ backgroundColor }}>
            <div style={{ transform: "scale(0.275)" }}>
              <a id="downloadLink" style={{ display: "none" }}></a>
              <Scene camera={CameraParams} id="canvasId" antialias>
                {children}
              </Scene>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Scene camera={CameraParams}>{children}</Scene>;
  }
}
