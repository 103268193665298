import { Light } from "~/gamehook";

export function LightKit() {
  const intensity = 1;
  return (
    <>
      <Light variant="directional" position={[2, 2, 0]} intensity={intensity} />
      <Light
        variant="directional"
        position={[-2, -2, 0]}
        intensity={intensity}
      />
      <Light
        variant="directional"
        position={[2, -2, 0]}
        intensity={intensity}
      />
      <Light
        variant="directional"
        position={[-2, 2, 0]}
        intensity={intensity}
      />
    </>
  );
}
