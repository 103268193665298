import { useMemo } from "react";

import { LightKit } from "~/LightKit";
import { Layer } from "./Layer";
import { GreenCircle } from "./GreenCircle";
import { AttractionLoop } from "~/kaleidoscope/AttractionLoop";
import {
  DisplayProperties,
  useDisplayProperties,
  useKaleidoscopeState,
} from "~/api";
import { PALETTES } from "~/colors";
import { KaleidoscopeScene } from "./KaleidoscopeScene";

interface Props {
  providedDisplayProperties?: DisplayProperties;
  pollForUpdates?: boolean;
  small?: boolean;
}
export function Kaleidoscope({ pollForUpdates, small }: Props) {
  const kaleidoscopeState = useKaleidoscopeState(pollForUpdates);
  const SceneContent = useMemo(() => {
    if (kaleidoscopeState === "attraction") {
      return <AttractionLoop />;
    } else if (kaleidoscopeState === "green") {
      return <GreenCircle />;
    }
    return (
      <>
        <Layers pollForUpdates={pollForUpdates} />
      </>
    );
  }, [kaleidoscopeState, pollForUpdates]);

  return (
    <KaleidoscopeScene small={small}>
      {SceneContent}
      <LightKit />
    </KaleidoscopeScene>
  );
}

function Layers({ pollForUpdates }: Props) {
  const displayProperties = useDisplayProperties(pollForUpdates);
  if (!displayProperties || !displayProperties.data) return null;

  const { palette_index } = displayProperties.data;
  const palette = PALETTES[palette_index];
  const layers = displayProperties.data.layers ?? [];

  return (
    <>
      {layers.map((layer, index) => {
        const depth = layers.length - 1 - index;
        return (
          <Layer
            color_index={layer.color_index}
            model_index={layer.model_index}
            palette={palette}
            key={depth}
            depth={depth}
          />
        );
      })}
    </>
  );
}

export function Deliverable({
  providedDisplayProperties,
}: {
  providedDisplayProperties: DisplayProperties;
}) {
  if (!providedDisplayProperties || !providedDisplayProperties.data)
    return null;
  const { palette_index } = providedDisplayProperties.data;
  const palette = PALETTES[palette_index];
  const layers = providedDisplayProperties.data.layers ?? [];

  return (
    <KaleidoscopeScene small>
      <LightKit />
      {layers.map((layer, index) => {
        const depth = layers.length - 1 - index;
        return (
          <Layer
            color_index={layer.color_index}
            model_index={layer.model_index}
            palette={palette}
            key={depth}
            depth={depth}
          />
        );
      })}
    </KaleidoscopeScene>
  );
}
