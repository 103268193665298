import "./styles.scss";

import { MouseEventHandler, ReactNode, useMemo } from "react";
import { Box } from "../box";
import { useIdentityContext } from "~/identities/Chooser/hooks";
import {
  MultiSelectSubcategories,
  getAvailableSubcategories,
} from "~/identities/categories";

type ButtonVariant = "contained" | "filled" | "text" | "buttonText";
type ColorVariant = "primary" | "selected";

interface IButton {
  className?: string;
  children: ReactNode;
  color?: ColorVariant;
  disabled?: boolean;
  fullWidth?: boolean;
  active?: boolean;
  onClick?: MouseEventHandler;
  size?: "small" | "medium" | "large";
  variant?: ButtonVariant;
  style?: object;
}
export function Button({
  active,
  color,
  disabled,
  fullWidth,
  className,
  children,
  onClick,
  size = "medium",
  variant = "contained",
  style,
}: IButton) {
  const buttonClassName = useMemo(() => {
    const classes = ["uiButton", variant];
    if (color) {
      classes.push(`color-${color}`);
    }
    if (disabled) {
      classes.push("disabled");
    }
    if (className) {
      classes.push(className);
    }
    if (active) {
      classes.push("active");
    }
    if (fullWidth) {
      classes.push("fullWidth");
    }
    if (size) {
      classes.push(`size-${size}`);
    }
    return classes.join(" ");
  }, [active, className, color, disabled, fullWidth, size, variant]);

  const handleClick = onClick && !disabled ? onClick : undefined;

  return (
    <button className={buttonClassName} onClick={handleClick} style={style}>
      {children}
    </button>
  );
}

interface Props {
  label: string;
  onClick: () => void;
  textSize?: "small";
  isSelected?: boolean;
}
export function ComponentSelectionButton({
  label,
  onClick,
  textSize,
  isSelected,
}: Props) {
  const { identity, subCategory } = useIdentityContext();
  const identityLabels = useMemo(() => {
    return identity.attributes.map((userAttribute) => {
      return {
        name: userAttribute.attribute.name,
        subCategory: userAttribute.subCategory,
      };
    });
  }, [identity.attributes]);

  // this is a little ugly, but working for now.
  const multiselectLabels: { options: string[]; subCategory: string }[] =
    useMemo(() => {
      const subCategoryName = subCategory?.name;
      if (!subCategoryName) {
        return [];
      }
      if (!MultiSelectSubcategories.includes(subCategoryName)) {
        return [];
      }
      const subCategoryMatrix = getAvailableSubcategories().find(
        (sc) => sc.name === subCategoryName
      )?.multiselectMatrix;
      if (!subCategoryMatrix) {
        return [];
      }
      const vals = identity.attributes
        .filter((a) => a.subCategory === subCategoryName)
        .flatMap((userAttribute) => {
          const matchingOption1 = subCategoryMatrix.options[0].find((o) =>
            userAttribute.attribute.name.startsWith(o)
          );
          if (!matchingOption1) {
            return [];
          }
          const option2 = userAttribute.attribute.name.split(
            `${matchingOption1} `
          )[1];
          return {
            options: [matchingOption1, option2],
            subCategory: userAttribute.subCategory,
          };
        })
        .filter((v) => v !== undefined);
      return vals;
    }, [identity.attributes, subCategory]);

  const styles = useMemo(() => {
    return textSize === "small"
      ? { textTransform: "none", fontSize: "20px" }
      : {};
  }, [textSize]);
  return (
    <Button
      variant="filled"
      onClick={onClick}
      className="componentSelectionButtonContainer"
      color={isSelected ? "selected" : undefined}
      // disable attribute button if use has already applied.
      disabled={
        !!identityLabels.find(
          (l) => l.name === label && l.subCategory === subCategory?.name
        ) ||
        !!multiselectLabels.find(
          (l) =>
            l.options.includes(label) && l.subCategory === subCategory?.name
        )
      }
    >
      <Box className="componentSelectionButtonInner" style={styles}>
        {label}
      </Box>
    </Button>
  );
}
