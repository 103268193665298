import * as THREE from "three";

import { useEffect, useMemo } from "react";
import { Positional, usePosition } from "~/gamehook/physics/position";
import { Movable, useMovement } from "~/gamehook/physics/movement";
import { useThreeScene } from "~/gamehook/scene/context";

interface BaseLightProps extends Movable, Positional {
  intensity?: number;
  color?: THREE.ColorRepresentation;
}
interface AmbientLightProps extends BaseLightProps {
  variant: "ambient";
}
interface DirectionalLightProps extends BaseLightProps {
  variant: "directional";
}
interface HemisphereLightProps extends BaseLightProps {
  variant: "hemisphere";
  skyColor?: THREE.ColorRepresentation;
  groundColor?: THREE.ColorRepresentation;
}
interface PointLightProps extends BaseLightProps {
  variant: "point";
  distance?: number;
  decay?: number;
}
interface RectAreaLightProps extends BaseLightProps {
  variant: "rectArea";
  width?: number;
  height?: number;
}
interface SpotLightProps extends BaseLightProps {
  variant: "spot";
  distance?: number;
  angle?: number;
  penumbra?: number;
  decay?: number;
}
type LightProps =
  | AmbientLightProps
  | DirectionalLightProps
  | HemisphereLightProps
  | PointLightProps
  | RectAreaLightProps
  | SpotLightProps;

function useLight(props: LightProps) {
  return useMemo(() => {
    if (props.variant === "ambient") {
      return new THREE.AmbientLight(props.color, props.intensity);
    } else if (props.variant === "directional") {
      return new THREE.DirectionalLight(props.color, props.intensity);
    } else if (props.variant === "hemisphere") {
      return new THREE.HemisphereLight(
        props.skyColor,
        props.groundColor,
        props.intensity
      );
    } else if (props.variant === "point") {
      return new THREE.PointLight(
        props.color,
        props.intensity,
        props.distance,
        props.decay
      );
    } else if (props.variant === "rectArea") {
      return new THREE.RectAreaLight(
        props.color,
        props.intensity,
        props.width,
        props.height
      );
    } else if (props.variant === "spot") {
      return new THREE.SpotLight(
        props.color,
        props.intensity,
        props.distance,
        props.angle,
        props.penumbra,
        props.decay
      );
    } else {
      throw new Error(`Unknown light type`);
    }
  }, [props]);
}

export function Light(props: LightProps) {
  const light = useLight(props);
  const scene = useThreeScene();

  useMovement(light, props);
  usePosition(light, props);

  useEffect(() => {
    scene.add(light);
    return () => {
      scene.remove(light);
    };
  }, [light, scene]);
  return <></>;
}
