import { useCallback, useState } from "react";

import { UserIdentity } from "./types";

export function useIdentityState() {
  const [identity, setIdentity] = useState<UserIdentity>({
    attributes: [],
    employmentStatus: undefined,
  });

  const handleIdentitySelection = useCallback((id: UserIdentity) => {
    setIdentity(id);
  }, []);

  return { identity, setIdentity: handleIdentitySelection };
}
