import * as THREE from "three";

import { createContext, useContext } from "react";
import { Object3D } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

interface Hierarchy {
  parent?: THREE.Object3D | GLTF;
}
export const HierarchyContext = createContext<Hierarchy>({
  parent: undefined,
});

export function useParent(): Object3D | undefined {
  const { parent } = useContext(HierarchyContext);
  if (!parent) {
    return undefined;
  } else if (isGLTF(parent)) {
    return parent.scene;
  } else {
    return parent;
  }
}

function isGLTF(obj: THREE.Object3D | GLTF): obj is GLTF {
  return obj instanceof THREE.Object3D !== true;
}
