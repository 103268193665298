import { useMemo } from "react";
import * as THREE from "three";
import { Materializable } from "./types";

export type { Materializable } from "./types";

export const DefaultMaterial = new THREE.MeshNormalMaterial();

export function useMaterial(props: Materializable): THREE.Material {
  const stringifiedParams = useMemo(() => {
    return props.material ? JSON.stringify(props.material) : undefined;
  }, [props.material]);

  return useMemo(() => {
    if (props.material instanceof THREE.Material) {
      return props.material;
    }

    if (!stringifiedParams) {
      return DefaultMaterial;
    }
    const material: Materializable["material"] = JSON.parse(stringifiedParams);
    if (!material) {
      return DefaultMaterial;
    } else if (material instanceof THREE.Material) {
      return material;
    }

    const params: THREE.MeshBasicMaterialParameters = {};
    switch (material.type) {
      case "basic":
        if (material.color) {
          params["color"] = material.color;
        }
        if (material.opacity) {
          params["opacity"] = material.opacity;
        }
        if (material.textures?.map) {
          params["map"] = loadTexture(material.textures.map);
        }
        return new THREE.MeshBasicMaterial(params);
      case "distance":
        return new THREE.MeshDistanceMaterial();
      case "lineBasic":
        return new THREE.LineBasicMaterial();
      case "lineDashed":
        return new THREE.LineDashedMaterial();
      case "lambert":
        return new THREE.MeshLambertMaterial();
      case "matcap":
        return new THREE.MeshMatcapMaterial();
      case "normal":
        return new THREE.MeshNormalMaterial();
      case "phong":
        return new THREE.MeshPhongMaterial();
      case "physical":
        return new THREE.MeshPhysicalMaterial();
      case "points":
        return new THREE.PointsMaterial();
      case "shader":
        return new THREE.ShaderMaterial();
      case "shadow":
        return new THREE.ShadowMaterial();
      case "sprite":
        return new THREE.SpriteMaterial();
      case "standard":
        return new THREE.MeshStandardMaterial();
      case "toon":
        return new THREE.MeshToonMaterial();
      default:
        return DefaultMaterial;
    }
  }, [stringifiedParams, props.material]);
}

const textureLoader = new THREE.TextureLoader();
function loadTexture(path: string) {
  return textureLoader.load(path);
}

export function hasMaterial(obj: THREE.Object3D): obj is THREE.Mesh {
  return true;
}
