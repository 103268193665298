import { Attribute } from "~/identities/types";
import { Box, Text } from "~/ui";
import { ScreenContainer } from "~/ui/screen";
import { ComponentSelectionButton } from "~/ui/button";

import { useIdentityContext } from "./hooks";
import { BackButton } from "./BackButton";
import { OtherCategoryMenu } from "./OtherCategoryMenu";
import { IdentityChart } from "./IdentityChart/IdentityChart";
import { useState } from "react";

const DEFAULT_INTENSITY = 50;

export function AttributeSelectionScreen() {
  const { category, subCategory, setUserAttribute, setScreenState } =
    useIdentityContext();

  /**
   * Update multiselect state based on selected matrix option.
   *
   * If all options in matrix are selected, proceed to next screen with options.
   */
  const handleMultiselectClick = (option: string, multiselectIndex: number) => {
    const newMultiselectState = {
      ...multiselectState,
    };

    if (multiselectState[multiselectIndex] === option) {
      delete newMultiselectState[multiselectIndex];
    } else {
      newMultiselectState[multiselectIndex] = option;
    }

    if (
      Object.entries(newMultiselectState).length ===
      subCategory?.multiselectMatrix?.options.length
    ) {
      handleClick({
        name: Object.values(newMultiselectState).join(" "),
        communityId: subCategory?.multiselectMatrix?.communityId,
      });
    } else {
      setMultiselectState(newMultiselectState);
    }
  };

  const [multiselectState, setMultiselectState] = useState<
    Record<number, string>
  >({});

  const handleClick = (attribute: Attribute) => {
    setUserAttribute({
      attribute,
      intensity: DEFAULT_INTENSITY,
      subCategory: subCategory?.name ?? "",
    });
    setScreenState("IntensitySelection");
  };
  if (!category || !subCategory) {
    throw new Error("Category not found");
  }

  return (
    <ScreenContainer>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: "100vh" }}
      >
        <Box style={{ height: "100%" }}>
          <Box>
            <BackButton />
          </Box>
          <Box>
            <Box display="flex">
              <Text variant="body1">
                Tap an attribute to add it to your identity.
              </Text>
            </Box>
            {subCategory.multiselectMatrix && (
              <>
                {subCategory.multiselectMatrix.options.map(
                  (optionsArray, multiselectIndex) => {
                    return (
                      <Box
                        display="flex"
                        style={{ width: "805px", flexWrap: "wrap" }}
                      >
                        {multiselectIndex > 0 && (
                          <Box display="flex" fullWidth>
                            <Text variant="body1">
                              Tap a qualifier to add it to your identity circle.
                            </Text>
                          </Box>
                        )}
                        {optionsArray.map((option) => {
                          const isSelected =
                            multiselectState[multiselectIndex] === option;
                          return (
                            <Box
                              key={option}
                              style={{
                                marginRight: "20px",
                                marginBottom: "20px",
                              }}
                            >
                              <ComponentSelectionButton
                                label={option}
                                onClick={() =>
                                  handleMultiselectClick(
                                    option,
                                    multiselectIndex
                                  )
                                }
                                textSize="small"
                                isSelected={isSelected}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  }
                )}
                <Box
                  style={{
                    paddingTop: "16px",
                    paddingBottom: "48px",
                    width: "900px",
                  }}
                >
                  <hr />
                </Box>
              </>
            )}

            <Box display="flex" style={{ width: "805px", flexWrap: "wrap" }}>
              {subCategory?.attributes.map((attribute) => {
                return (
                  <Box
                    style={{
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                    key={attribute.name}
                  >
                    <ComponentSelectionButton
                      label={attribute.name}
                      onClick={() => handleClick(attribute)}
                      textSize="small"
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box>
          <OtherCategoryMenu />
        </Box>
      </Box>
      <Box>
        <IdentityChart />
      </Box>
    </ScreenContainer>
  );
}
