import { Box, Button } from "~/ui";
import { Category } from "~/identities/types";

import { useIdentityContext } from "./hooks";

export function CategoryButton({
  category,
  subCategoryLabel,
}: {
  category: Category;
  subCategoryLabel?: string;
}) {
  const context = useIdentityContext();

  const handleClick = (category: Category) => {
    context.setCategory(category);
    context.setScreenState("SubCategorySelection");
  };

  return (
    <Button
      key={category.name}
      variant="text"
      onClick={() => handleClick(category)}
      className="categoryButton"
      style={{ padding: 0, width: 720 }}
    >
      <Box
        className="categoryButtonContainer"
        display="flex"
        my={2}
        p={3}
        justifyContent="flex-start"
        alignItems="center"
      >
        <img src={category.icon} alt={`Icon for ${category.name}`} />
        <Box pl={4}>{category.name}</Box>
        {subCategoryLabel && <Box>/ {subCategoryLabel}</Box>}
      </Box>
    </Button>
  );
}
