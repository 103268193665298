import { MouseEventHandler, ReactNode, useCallback, useMemo } from "react";
import { Text } from "~/ui";

import "./styles.scss";

export interface IModal {
  children: ReactNode;
  name: string;
  open: boolean;
  onClose: () => void;
  title: string;
}

export function Modal({ open, onClose, children, title }: IModal) {
  const wrapperClass = useMemo(() => {
    return ["uiModal", "wrapper", open ? "open" : "closed"].join(" ");
  }, [open]);

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  return (
    <div className={wrapperClass} onClick={handleClick}>
      <div
        className="container"
        style={{ height: 'auto' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="header">
          <Text variant="h1">{title}</Text>
        </div>
        {children}
      </div>
    </div>
  );
}
