import React, { CSSProperties, useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  useRouteError,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { AttractionLoop } from "~/kaleidoscope/AttractionLoop";
import { KaleidoscopeScene } from "~/kaleidoscope/KaleidoscopeScene";

const ErrorBoundaryStyles: CSSProperties = {
  position: "fixed",
  padding: 32,
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
};
const ErrorTextStyles: CSSProperties = {
  color: "#ffffff",
  padding: 0,
  textAlign: "center",
  margin: 0,
};

const sentryEnabled = import.meta.env.VITE_SENTRY_ENABLED === 'true' &&
  !!import.meta.env.VITE_SENTRY_DSN_URI;

if (sentryEnabled) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_URI,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}


export function ErrorScreen() {
  const routeError = useRouteError();

  useEffect(() => {
    if (sentryEnabled) {
      Sentry.captureException(routeError, { level: "error" });
    }
  }, [routeError]);

  return (
    <div>
      <div style={ErrorBoundaryStyles}>
        <div>
          <h1 style={ErrorTextStyles}>Sorry, something went wrong</h1>
          <h2 style={ErrorTextStyles}>Please try again later</h2>
        </div>
      </div>
      <KaleidoscopeScene>
        <AttractionLoop />
      </KaleidoscopeScene>
    </div>
  );
}
