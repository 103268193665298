import { useMemo } from "react";
import { Box, Button, Text } from "~/ui";

import { useIdentityContext } from "./hooks";
import LeftArrowDark from "~/assets/LeftArrowDark.svg";

export function BackButton() {
  const {
    category,
    subCategory,
    setCategory,
    setScreenState,
    userAttribute,
    setSubCategory,
    setUserAttribute,
  } = useIdentityContext();

  /**
   * Return to the previous screen, clearing appropriate state based on current status.
   */
  const handleClick = () => {
    if (userAttribute) {
      setUserAttribute(undefined);
      setScreenState("AttributeSelection");
      return;
    }

    if (subCategory) {
      setSubCategory(undefined);
      setScreenState("SubCategorySelection");
      return;
    }

    if (category) {
      setCategory(undefined);
      setScreenState("CategorySelection");
      return;
    }
  };
  const categoryLabel = category?.name ?? "";
  const categoryIcon = category?.icon ?? "";
  const subCategoryLabel = subCategory?.name ?? undefined;

  const labelComponent = useMemo(() => {
    if (userAttribute) {
      // Intensity Selection screen
      return (
        <Box pl={4} display="flex">
          <Text
            variant="body1"
            style={{
              fontSize: "32px",
              letterSpacing: "0px",
              fontWeight: "bold",
              lineHeight: "normal",
              textAlign: "left",
              width: "840px",
            }}
          >
            {userAttribute.attribute.name}
          </Text>
        </Box>
      );
    } else if (subCategoryLabel) {
      // Attribute Selection Screen
      return (
        <Box pl={3} display="flex">
          <Text
            variant="subtitle"
            className="uppercase"
            style={{ letterSpacing: 0, fontWeight: 100, fontSize: "40px" }}
          >
            {categoryLabel}
            <span style={{ fontWeight: 700 }}> / {subCategoryLabel}</span>
          </Text>
        </Box>
      );
    } else if (category) {
      return (
        <Box pl={3} display="flex">
          <Text
            variant="subtitle"
            className="uppercase"
            style={{ fontWeight: 700, letterSpacing: 0, fontSize: "40px" }}
          >
            {categoryLabel}
          </Text>
        </Box>
      );
    }
  }, [category, categoryLabel, subCategoryLabel, userAttribute]);

  return (
    <Button
      variant="text"
      onClick={handleClick}
      style={{
        paddingLeft: 0,
      }}
    >
      <Box display="flex" alignItems="center">
        <img src={LeftArrowDark} alt="left arrow" />
        {categoryIcon && categoryLabel && !userAttribute && (
          <Box pl={3}>
            <img src={categoryIcon} alt={`${categoryLabel} icon`} />
          </Box>
        )}
        {labelComponent}
      </Box>
    </Button>
  );
}
