import { ReactNode } from "react";

import { Box } from "~/ui";

export function ScreenContainer({
  children,
  customStyles,
}: {
  children: ReactNode;
  customStyles?: Record<string, string>;
}) {
  return (
    <Box
      fullWidth
      fullHeight
      style={{
        padding: "32px 64px",
        ...customStyles,
      }}
    >
      {children}
    </Box>
  );
}
