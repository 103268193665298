import { buildRoute } from "~/config";
import { AnimationSettings } from "~/gamehook/models";

export const MODEL_PATHS_AND_OFFSETS: [string, number][] = [
  ["1.gltf", 0],
  ["2.gltf", 7],
  ["3.gltf", 1],
  ["4.gltf", -0.25],
  ["5.gltf", 1],
  ["6.gltf", 0.5],
  ["7.gltf", 0],
  ["8.gltf", -1.25],
  ["9.gltf", -0.5],
  ["10.gltf", -0.75],
  ["11.gltf", 0.5],
  ["12.gltf", 0],
  ["13.gltf", 0],
  ["14.gltf", 0.35],
  ["15.gltf", 1],
  ["16.gltf", -0.25],
  ["17.gltf", 0.25],
  ["18.gltf", -0.75],
  ["19.gltf", 0],
  ["20.gltf", 0],
];

const search = new URLSearchParams(window.location.search);
const STARTING_POSITION = search.get("initialZoom") ?? "-2.2";
const STEP = search.get("step") ?? "0.5";

interface GetModelAndScaleParams {
  depth: number;
  index: number;
}
export function getModelAndZ({
  depth,
  index,
}: GetModelAndScaleParams): [string, number] {
  const [path, offset] = MODEL_PATHS_AND_OFFSETS[index];
  const fullPath = buildRoute(`/models/${path}`);
  const z = (() => {
    return parseFloat(STARTING_POSITION) - depth * parseFloat(STEP) - offset;
  })();
  return [fullPath, z];
}
export const DefaultAnimation: AnimationSettings = {
  name: "animation_0",
  loopMode: "once",
};
