/* Legacy Palette
export const PALETTES: number[][] = [
  [0xc13859, 0xe47a0c, 0xefc100],
  [0xe47a0c, 0xefc100, 0xc4d600],
  [0xefc100, 0xc4d600, 0x00abab],
  [0xc4d600, 0x00abab, 0x0076a8],
  [0x00abab, 0x0076a8, 0x834ac6],
  [0x00a3e0, 0x00abab, 0x005587],
  [0xc4d600, 0x43b02a, 0x007680],
  [0xc4d600, 0x00a3e0, 0x834ac6],
];
*/

export type PALETTE = [number, number, number, number, number, number];
export const PALETTES_V1: PALETTE[] = [
  [0xc13859, 0xe47a0c, 0xefc100, 0xce0d0d, 0xd05438, 0xeba804],
  [0xe47a0c, 0xefc100, 0xc4d600, 0xd05438, 0xeba804, 0xefe800],
  [0xefc100, 0xc4d600, 0x00abab, 0xefe800, 0x82c839, 0x3eb876],
  [0xc4d600, 0x00abab, 0x0076a8, 0x43b02a, 0x3eb876, 0x007680],
  [0x00abab, 0x0076a8, 0x834ac6, 0x007680, 0x005587, 0x433da5],
  [0x00a3e0, 0x00abab, 0x005587, 0x007680, 0x3eb876, 0x82c839],
  [0xeba804, 0xefc100, 0x007680, 0xc4d600, 0x00a3e0, 0x00abab],
  [0xc13859, 0xd05438, 0xe47a0c, 0x003149, 0x005587, 0x433da5],
];

export const LIGHTER_PALETTES: PALETTE[] = [
  [0xff4080, 0xff7900, 0xffce00, 0xff2424, 0xff6555, 0xffb000],

  [0xff7900, 0xffce00, 0xc4d600, 0xff6555, 0xffb000, 0xefe800],

  [0xffce00, 0xc4d600, 0x00ddd7, 0xefe800, 0x94ea24, 0x26ed7b],

  [0xc4d600, 0x00ddd7, 0x00c9ff, 0x94ea24, 0x26ed7b, 0x0068ff],

  [0x00ddd7, 0x00c9ff, 0x9f5cff, 0x26ed7b, 0x0068ff, 0x520bff],

  [0xff9700, 0xffce00, 0x00ddd7, 0xc4d600, 0x00c9ff, 0x26ed7b],

  [0xff4080, 0xff6555, 0xffb000, 0x0068ff, 0x00c9ff, 0x9f5cff],

  [0xff2424, 0xff7900, 0xffce00, 0xefe800, 0x00c9ff, 0x00ddd7],
];

const search = new URLSearchParams(window.location.search);
export const PALETTES =
  search.get("palette") === "1" ? PALETTES_V1 : LIGHTER_PALETTES;
