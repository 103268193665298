import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { COMMUNITY_GROUP_QUERY_PARAM_KEY } from "~/identities/constants";
import { Resource, ResourceType } from "../identities/types";
import {
  getResourceByTypeAndIdx,
  getResourceByCommunityGroupId,
  getRandomizedResourceByType,
} from "~/identities/resources";
import { Deliverable } from "~/kaleidoscope";
import { Box, Text, Button } from "~/ui";
import { ResourceLink } from "~/ui/link";
import { ScreenContainer } from "~/ui/screen";
import { decodeDisplayProperties } from "~/urls";

export function ResourcesScreen() {
  const { search } = useLocation();
  const data = new URLSearchParams(window.location.search);
  const communityGroupIdVal = new URLSearchParams(search).get(
    COMMUNITY_GROUP_QUERY_PARAM_KEY
  );
  const communityGroupId = communityGroupIdVal
    ? Number(communityGroupIdVal)
    : undefined;
  const isExternal = data.get("e") === "1" ? true : false;
  const resourcesList: Resource[] = [];
  const additionalResourcesList: Resource[] = [];

  // updated resource list logic
  if (isExternal) {
    // SCENARIO 1
    resourcesList.push(
      getResourceByTypeAndIdx(ResourceType.ExternalPrimary, 0)
    );
    additionalResourcesList.push(getRandomizedResourceByType(ResourceType.External));
    additionalResourcesList.push(getRandomizedResourceByType(ResourceType.External));
  } else if (!isExternal && !communityGroupId) {
    // SCENARIO 2
    resourcesList.push(
      getResourceByTypeAndIdx(ResourceType.InternalPrimary, 0)
    );
    additionalResourcesList.push(getRandomizedResourceByType(ResourceType.External));
    additionalResourcesList.push(getRandomizedResourceByType(ResourceType.Internal));
  } else if (!isExternal && communityGroupId) {
    // SCENARIO 3
    resourcesList.push(getResourceByCommunityGroupId(communityGroupId));
    additionalResourcesList.push(getRandomizedResourceByType(ResourceType.Internal));
    additionalResourcesList.push(getRandomizedResourceByType(ResourceType.External));
  }

  const handleDownload = useCallback(() => {
    const canvas: HTMLCanvasElement | null = document.getElementById(
      "canvasId"
    ) as HTMLCanvasElement;

    // Create a temporary 2D canvas
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;

    const tempCtx = tempCanvas.getContext('2d');

    if (!tempCtx) {
      return;
    }

    // Generate a full-sized rect as furthermost layer
    tempCtx.fillStyle = '#353333';
    tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

    // Draw the original image onto the 2D canvas
    //tempCtx.drawImage(canvas, -900, 0);
    tempCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, -900, -100, canvas.width * 1.2, canvas.height * 1.2);


    // Add text to the 2D canvas
    tempCtx.fillStyle = 'white';
    tempCtx.font = '48px Avenir, sans-serif';
    tempCtx.fillText('#BelongingAtDU', 1450, 100);

    const link = document.createElement<'a'>('a');
    link.download = 'background.png';

    // Download the modified image
    link.href = tempCanvas.toDataURL();
    link.click();
  }, []);

  return (
    <ScreenContainer
      customStyles={{
        overflowY: "scroll",
        overflowX: "hidden",
        padding: "32px 16px",
      }}
    >
      <Box
        style={{
          marginBottom: "64px",
        }}
      >
        <Box
          style={{
            textAlign: "center",
          }}
        >
          <Text variant="body2" style={{ letterSpacing: 3 }}>
            Share Your Perspective
          </Text>
          <Text
            variant="title"
            style={{
              fontSize: "23px",
              letterSpacing: 4,
            }}
          >
            Inside the Green Dot
          </Text>
          <DownloadableResource />
          <Button
            style={{
              letterSpacing: 1,
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
            onClick={handleDownload}
          >
            Download Your Visualization
          </Button>
        </Box>

        <Box
          style={{
            paddingTop: '35px',
            paddingBottom: 0
          }}>
          <i>Use your unique visualization as a Zoom background, a desktop image, or as a keepsake of your DU experience.</i>
        </Box>

        <Box
          style={{
            margin: "auto",
            paddingBottom: '16px',
            textAlign: "left",
          }}
        >
          <Text
            variant="h3"
            style={{
              textTransform: "uppercase",
              paddingTop: "40px",
              paddingBottom: "20px",
              margin: 0,
            }}
          >
            Get Connected!
          </Text>

          {resourcesList.map((resource) => {
            return (
              <Box key={resource.label}>
                <ResourceLink label={resource.label} to={resource.link} />
              </Box>
            );
          })}

          <Text
            variant="h3"
            style={{
              textTransform: "uppercase",
              paddingTop: "45px",
              paddingBottom: 0,
              margin: "0",
            }}
          >
            Have a Few More Minutes?
          </Text>

          <Text
            variant="body2"
            style={{
              textAlign: 'left',
              margin: '0 0 15px 0'
            }}>
            Explore these additional Deloitte DEI resources:
          </Text>

          {additionalResourcesList.map((resource) => {
            return (
              <Box key={resource.label}>
                <ResourceLink label={resource.label} to={resource.link} />
              </Box>
            );
          })}

          <Text
            variant="body2"
            textAlign="left"
            style={{ fontSize: "20px", lineHeight: "32px" }}
          >
            <span style={{ fontWeight: "bold" }}>While you're at DU</span>,
            check out the{" "}
            <span style={{ fontWeight: "bold" }}>
              Purpose at DU section of the DU mobile app
            </span>{" "}
              to learn more about the multiple ways we celebrate DEI and our commitment to 
              making an impact that matters. Our goal at DU is to ensure an inclusive and 
              equitable environment for all guests. From celebrating our History and Heritage 
              Months to introducing products from diverse suppliers, you'll see our diverse 
              culture celebrated in a variety of ways during your stay.
          </Text>
        </Box>
      </Box>
    </ScreenContainer>
  );
}

function DownloadableResource() {
  const search = window.location.search;
  const displayData = useMemo(() => {
    const data = new URLSearchParams(search);
    const rawDisplayData = data.get("key");
    if (!rawDisplayData) return undefined;
    const displayData = decodeDisplayProperties(rawDisplayData);
    return displayData;
  }, [search]);
  return displayData ? (
    <Deliverable providedDisplayProperties={displayData} />
  ) : null;
}
