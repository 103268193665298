import { useEffect } from "react";
import { XYZ } from "./types";
import { isCamera } from "~/gamehook/camera/guards";

export interface Positional {
  orientation?: XYZ;
  position?: XYZ;
  scale?: XYZ;
  startingZ?: number;
}

export function usePosition(
  obj: THREE.Object3D | undefined,
  {
    startingZ,
    position = [0, 0, 0],
    orientation = [0, 0, 0],
    scale = [1, 1, 1],
  }: Positional
) {
  const [posX, posY, posZ] = position;

  // Position
  useEffect(() => {
    obj?.position.setX(posX);
    if (isCamera(obj)) {
      // obj?.updateMatrix();
    }
  }, [obj, posX]);
  useEffect(() => {
    obj?.position.setY(posY);
    if (isCamera(obj)) {
      // obj.updateMatrix();
    }
  }, [obj, posY]);

  useEffect(() => {
    obj?.position.setZ(posZ);
    if (isCamera(obj)) {
      obj.updateMatrix();
    }
  }, [obj, posZ, startingZ]);

  // Orientation
  useEffect(() => {
    obj?.rotation.set(...orientation);
  }, [obj, orientation]);

  // Scale
  useEffect(() => {
    obj?.scale.set(...scale);
  }, [obj, scale]);
}
