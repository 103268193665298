import { useEffect } from "react";

const BASE_URL = '';

export function buildRoute(routeName: string): string {
  return `${BASE_URL}${routeName}`;
}

// Refresh the web browser every ~24 hours to ensure the page captures any updates we push
// and clear potential error states. This will happen at 10pm every day
const TARGET_HOUR = 22;

// Check every 15 minutes to see if we're in the target hour
// This will lead to 3-4 refreshes during the target hour, but ensures
// we won't miss a day
const CHECK_EVERY_X_MINUTES = 15;
const REFRESH_INTERVAL = 1000 * 60 * CHECK_EVERY_X_MINUTES;

export function useRefresh() {
  useEffect(() => {
    const interval = setInterval(() => {
      const time = new Date();
      if (time.getHours() === TARGET_HOUR) {
        window.location.reload();
      }
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);
}
