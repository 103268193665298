import * as THREE from "three";
import { useCallback, useEffect, useState } from "react";

import { useThreeScene } from "~/gamehook/scene/context";
import { useCamera } from "~/gamehook/camera/hooks";

import { Interaction, Interactable } from "./types";
export type { Interactable };

export function useInteraction(
  model: THREE.Object3D | undefined,
  props: Interactable
) {
  const { onClick, onHoverExit } = props;
  const [hovered, setHovered] = useState(false);

  // TODO: Fighting an issue with flickering triggers of onHover
  // Hacky fix is to set a lastHoverEventAt param as a form of debouncing
  const [lastHoverEventAt, setLastHoverEventAt] = useState(Date.now());

  const camera = useCamera();
  const scene = useThreeScene();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (!model || !onClick) {
        return;
      }
      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      // Create a raycaster and set its origin and direction
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);

      // Perform the raycasting and get the intersections with objects
      const intersects = raycaster.intersectObjects(scene.children, true);
      const firstIntersection =
        intersects.length > 0 ? intersects[0] : undefined;

      // Check if any object was intersected
      const clickedObject = firstIntersection?.object;
      if (!clickedObject) {
        return;
      }
      const clickHandler = detectEventHandler(clickedObject, model, "onClick");
      const interaction: Interaction = {
        ...firstIntersection.point,
        ...event,
      };
      if (clickHandler) {
        clickHandler(interaction);
      }
    },
    [camera, scene.children, onClick, model]
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!model) {
        return;
      }

      // Detect if we hovered or unhovered too recently
      const HOVER_DEBOUNCE = 50;
      if (Date.now() - lastHoverEventAt < HOVER_DEBOUNCE) {
        return;
      }

      // Determine hovered object
      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      // Create a raycaster and set its origin and direction
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);

      // Perform the raycasting and get the intersections with objects
      const intersects = raycaster.intersectObjects(scene.children, true);
      const firstIntersection =
        intersects.length > 0 ? intersects[0] : undefined;

      // Detect hover handlers
      const hoverHandler = firstIntersection
        ? detectEventHandler(firstIntersection.object, model, "onHoverEnter")
        : undefined;
      if (hoverHandler && !hovered) {
        const interaction: Interaction = {
          ...event,
          x: 0,
          y: 0,
          z: 0,
        };
        hoverHandler(interaction);
        setLastHoverEventAt(Date.now());
        setHovered(true);
      }

      // Detect unhover handlers
      const currentHovered = intersects.find((i) => i.object.id === model.id);
      if (!currentHovered && hovered) {
        if (onHoverExit) {
          onHoverExit(event);
        }
        setLastHoverEventAt(Date.now());
        setHovered(false);
      }
    },
    [camera, hovered, lastHoverEventAt, onHoverExit, scene.children, model]
  );

  // Click
  useEffect(() => {
    if (props.onClick && model) {
      window.addEventListener("click", handleClick);
    }
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [model, handleClick, props.onClick]);

  useEffect(() => {
    if (model && props.onClick) {
      model.userData["onClick"] = props.onClick;
    } else if (model?.userData["onClick"]) {
      model.userData["onClick"] = undefined;
    }
  }, [model, props.onClick]);

  // Hover
  useEffect(() => {
    if (model && (props.onHoverEnter || props.onHoverExit)) {
      window.addEventListener("mousemove", handleMouseMove);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [props.onHoverEnter, props.onHoverExit, handleMouseMove, model]);

  useEffect(() => {
    if (model && (props.onHoverEnter || props.onHoverExit)) {
      model.userData["onHoverEnter"] = props.onHoverEnter;
      model.userData["onHoverExit"] = props.onHoverExit;
    } else if (model?.userData["onClick"]) {
      model.userData["onHoverEnter"] = undefined;
      model.userData["onHoverExit"] = undefined;
    }
  }, [model, props.onHoverEnter, props.onHoverExit]);
}

function detectEventHandler(
  obj: THREE.Object3D | null,
  model: THREE.Object3D,
  handlerName: "onClick" | "onHoverEnter" | "onHoverExit"
): Interactable["onClick"] | Interactable["onHoverEnter"] | undefined {
  if (!obj || !model) {
    return;
  }
  let current: THREE.Object3D | null = obj;
  let count = 20;

  while (current != null && count) {
    if (current?.userData[handlerName] && current.id === model.id) {
      return current.userData[handlerName];
    }
    current = current.parent;
    count -= 1;
  }
}
