import { useState, useEffect } from "react";
import { Box, Text } from "~/ui";

interface Props {
  backgroundColor: string;
  color: string;
  label: string;
  setSelectedItem: () => void;
  shouldCollapse: boolean;
  id: string;
  touch?: Touch;
}

/**
 * @todo-nitz-critical
 *
 * couple of things to fine tune...
 * - variable width -- make sure working for different text lengths.
 *   - might need to adjust padding as well (?)
 */
export function ChartBar({
  backgroundColor,
  color,
  label,
  setSelectedItem,
  shouldCollapse,
  id,
  touch,
}: Props) {
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    if (el && touch) {
      const boundingBox = el?.getBoundingClientRect();
      const { clientX, clientY } = touch;
      const isWithinX =
        boundingBox.left < clientX && boundingBox.right > clientX;
      const isWithinY =
        boundingBox.top < clientY && boundingBox.bottom > clientY;
      const isSelected = isWithinX && isWithinY;

      setIsSelected(isSelected);
      if (isSelected) {
        setSelectedItem();
      }
    } else {
      setIsSelected(false);
    }
  }, [touch]);

  const el = document.getElementById(id);
  const unselectedHeight = shouldCollapse ? "1vh" : "10vh";
  const height = isSelected ? "10.75vh" : unselectedHeight;
  const showLabel = !shouldCollapse || isSelected;

  // @todo-nitz-critical test & fine tune this against different text lengths.
  const width = shouldCollapse ? "200px" : "350px";

  return (
    <Box
      id={id}
      key={label}
      style={{
        backgroundColor,
        color,
        boxSizing: "border-box",
        padding: 8,
        height,
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        justifyContent: "center",
        transition: "height 0.3s ease",
      }}
    >
      <Box
        style={{
          width,
          right: 0,
          display: "flex",
        }}
      >
        <Text variant="h4" style={{ textAlign: "right", width: "100%" }}>
          {showLabel ? label : undefined}
        </Text>
      </Box>
    </Box>
  );
}
