import { Box, Button, Text } from "~/ui";
import { UserAttribute } from "../types";
import { Slider } from "~/ui/Slider";
import { useEffect, useMemo, useState } from "react";
import { getColor } from "~/ui/colors";
import { useIdentityContext } from "./hooks";

interface Props {
  onDone: () => void;
  onChange: (value: number) => void;
  intensity: number;
  userAttribute: UserAttribute;
}
const SLIDER_WIDTH = "calc(100vw - 50vh - 64px)";

export function IntensitySlider({
  intensity,
  onDone,
  onChange,
  userAttribute,
}: Props) {
  const { identity } = useIdentityContext();
  const nextColor = useMemo(() => {
    const existingAttributeIndex = identity.attributes.findIndex(
      (a) =>
        a.attribute.name === userAttribute.attribute.name &&
        a.subCategory === userAttribute.subCategory
    );
    const colorIndex =
      existingAttributeIndex > -1
        ? existingAttributeIndex
        : identity.attributes.length;
    return getColor(colorIndex);
  }, [identity, userAttribute]);

  const [isClean, setIsClean] = useState(true);

  useEffect(() => {
    setIsClean(true);
  }, [userAttribute.attribute.name]);

  /**
   * this is a bit hacky, but fixes a rendering issue where the border box of the larger add button
   * would sporadically still be visible when props update to show the smaller one.
   */
  useEffect(() => {
    const forceRepaint = () => {
      document.body.style.display = "none";
      requestAnimationFrame(() => {
        document.body.offsetHeight; // Trigger repaint
        document.body.style.display = "";
      });
    };

    forceRepaint();
  }, [isClean, userAttribute.attribute.name]);

  return (
    <>
      <Box
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: SLIDER_WIDTH,
          paddingTop: 64,
        }}
      >
        <Slider
          color={nextColor}
          onChange={(val) => {
            setIsClean(false);
            onChange(val);
          }}
          value={intensity}
        />
      </Box>
      <AddButton isClean={isClean} onDone={onDone} />
    </>
  );
}
function AddButton({
  isClean,
  onDone,
}: {
  isClean: boolean;
  onDone: () => void;
}) {
  return (
    <Box
      style={{
        paddingTop: "96px",
        width: "50%",
      }}
    >
      <Button onClick={onDone} variant="contained" size="large">
        <Text
          style={{
            fontSize: "32px",
            fontWeight: "bold",
            textTransform: "uppercase",
            margin: 0,
            padding: 0,
            letterSpacing: "4px",
            whiteSpace: "nowrap",
          }}
        >
          {isClean ? "Add without adjusting" : "Add"}
        </Text>
      </Button>
    </Box>
  );
}
