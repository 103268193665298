import { Resource, ResourceType } from './types';

export const Resources: Resource[] = [
  {
    label: `DEI at Deloitte`,
    link: `https://www2.deloitte.com/us/en/pages/about-deloitte/topics/inclusion.html`,
    type: ResourceType.ExternalPrimary
  },
  {
    label: `Well-Being General Site`,
    link: `https://www2.deloitte.com/us/en/pages/about-deloitte/articles/inclusion-work-life-fit.html`,
    type: ResourceType.External
  },
  {
    label: `US DEI Transparency Report`,
    link: `https://www2.deloitte.com/us/en/pages/about-deloitte/articles/dei/diversity-equity-inclusion-transparency-report.html`,
    type: ResourceType.External
  },
  {
    label: `Workforce Data`,
    link: `https://www2.deloitte.com/us/en/pages/about-deloitte/articles/dei/us-workforce-data.html`,
    type: ResourceType.External
  },
  {
    label: `DEI Institute Homepage`,
    link: `https://www2.deloitte.com/us/en/pages/about-deloitte/articles/deloitte-dei-institute.html`,
    type: ResourceType.External
  },
  {
    label: `The Equality Imperative`,
    link: `https://www2.deloitte.com/us/en/pages/about-deloitte/articles/the-equity-imperative.html`,
    type: ResourceType.External
  },
  {
    label: `Committing to DEI Goals`,
    link: `https://www2.deloitte.com/content/dam/Deloitte/us/Documents/about-deloitte/us-deloitte-committing-to-dei-goals-shifting-pov.pdf`,
    type: ResourceType.External
  },
  {
    label: `Taking Bold Action for Equitable Outcomes`,
    link: `https://www2.deloitte.com/us/en/insights/focus/human-capital-trends/2023/diversity-equity-inclusion-belonging.html`,
    type: ResourceType.External
  },
  {
    label: `Building a Workforce Development Ecosystem`,
    link: `https://www2.deloitte.com/content/dam/Deloitte/us/Documents/consulting/us-building-a-workforce-development-ecosystem-that-works-supporting-vulnerable-population.pdf`,
    type: ResourceType.External
  },
  {
    label: `2023 Global Human Capital Trends`,
    link: `https://www2.deloitte.com/us/en/insights/focus/human-capital-trends.html`,
    type: ResourceType.External
  },
  {
    label: `From Great Resignation to Great Reimagination`,
    link: `https://www.deloitte.com/content/dam/assets-shared/legacy/docs/gx-tgr-equity.pdf?_gl=1*1mgh1vy*_gcl_au*Mjc0NjY2MDQyLjE2ODg3NTUxMzA.`,
    type: ResourceType.External
  },
  {
    label: `Build Trust in DEI Commitments`,
    link: `https://www2.deloitte.com/us/en/insights/topics/talent/building-employee-trust-dei-programs.html`,
    type: ResourceType.External
  },
  {
    label: `Digital Access for All`,
    link: `https://www2.deloitte.com/us/en/insights/industry/public-sector/government-trends/2022/digital-access-government.html`,
    type: ResourceType.External
  },

  {
    label: `DEI Communities at Deloitte`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/Communities.aspx `,
    type: ResourceType.InternalPrimary
  },

  {
    label: `DEI Homepage`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/home.aspx`,
    type: ResourceType.Internal
  },
  {
    label: `DEI Strategy`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/strategy.aspx`,
    type: ResourceType.Internal
  },
  {
    label: `DEI Resources, Learning & Development Homepage`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/Resources.aspx`,
    type: ResourceType.Internal
  },
  {
    label: `Allyship Homepage`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/Allyship.aspx`,
    type: ResourceType.Internal
  },
  {
    label: `Art of Allyship Scroll-e-Learner`,
    link: `https://develop.deloitte.com/art-of-allyship`,
    type: ResourceType.Internal
  },
  {
    label: `Art of Allyship Scroll-e-Learner`,
    link: `https://develop.deloitte.com/art-of-allyship`,
    type: ResourceType.Internal
  },
  {
    label: `Previous Recordings of Various DEI Events`,
    link: `https://deloitte.zoomforth.com/dei-resources#/subpage/national-dei-events`,
    type: ResourceType.Internal
  },

  {
    label: `Black & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-black-allies.aspx`,
    type: ResourceType.Community,
    group: 1
  },
  {
    label: `East/Southeast Asian & Allies Community `,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-ese-asian-allies.aspx`,
    type: ResourceType.Community,
    group: 2
  },
  {
    label: `Hispanic/Latinx & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-latinx-allies.aspx`,
    type: ResourceType.Community,
    group: 3
  },
  {
    label: `LGBTQIA+ & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-lgbtqia-allies.aspx`,
    type: ResourceType.Community,
    group: 4
  },
  {
    label: `Middle Eastern/North African & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-mena-allies.aspx`,
    type: ResourceType.Community,
    group: 5
  },
  {
    label: `Native American, Pacific Islander & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-napi-allies-v2.aspx`,
    type: ResourceType.Community,
    group: 6
  },
  {
    label: `People with Disabilities & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-disabilities-allies.aspx`,
    type: ResourceType.Community,
    group: 7
  },
  {
    label: `South Asian & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-south-asian-allies.aspx`,
    type: ResourceType.Community,
    group: 8
  },
  {
    label: `Veterans, Military, Spouses & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-military-allies.aspx`,
    type: ResourceType.Community,
    group: 9
  },
  {
    label: `Women & Allies Community`,
    link: `https://resources.deloitte.com/sites/US/About/Bus/Talent/USDEI/Pages/community-women-allies.aspx`,
    type: ResourceType.Community,
    group: 10
  }
];

export const getResourceByTypeAndIdx = (type: ResourceType, idx: number) => {
  const matchedResources = Resources.filter((r: Resource) => r.type === type);
  return matchedResources[idx];
};

export const getResourceByCommunityGroupId = (id: number) => {
  return Resources.filter((r: Resource) => r.group === id)[0];
};

export const getRandomizedResourceByType = (type: ResourceType) => {
  const matchedResources = Resources.filter((r: Resource) => r.type === type);
  return matchedResources[Math.floor(Math.random() * matchedResources.length)];
};
