import React, { ReactNode, useMemo } from "react";
import "./styles.scss";

import { ISpacing } from "~/ui/types";

type TouchHandler = (e: React.TouchEvent<HTMLDivElement>) => void;
type MouseHandler = (e: React.MouseEvent<HTMLDivElement>) => void;
interface IBox extends ISpacing {
  backgroundColor?: "red" | "purple" | "primary";
  children: ReactNode;
  className?: string;
  id?: string;

  display?: "flex" | "grid";
  flexDirection?: "column";
  justifyContent?:
    | "flex-start"
    | "space-between"
    | "center"
    | "flex-end"
    | "space-around";
  alignItems?: "center" | "flex-end";

  fullHeight?: boolean;
  fullWidth?: boolean;
  style?: object;

  onMouseUp?: MouseHandler;
  onMouseMove?: MouseHandler;
  onMouseDown?: MouseHandler;
  onTouchStart?: TouchHandler;
  onTouchEnd?: TouchHandler;
  onTouchMove?: TouchHandler;
}
export function Box({
  alignItems,
  backgroundColor,
  className,
  children,
  display,
  flexDirection,
  fullHeight,
  fullWidth,
  justifyContent,
  id,
  p,
  px,
  py,
  pt,
  pb,
  pl,
  pr,

  m,
  mx,
  my,
  mt,
  mb,
  ml,
  mr,
  style,

  onTouchEnd,
  onTouchMove,
  onTouchStart,
  onMouseUp,
  onMouseDown,
  onMouseMove,
}: IBox) {
  const classes = useMemo(() => {
    const classNames = ["uiBox"];
    if (className) {
      classNames.push(className);
    }
    if (backgroundColor) {
      classNames.push(`backgroundColor-${backgroundColor}`);
    }
    if (display) {
      classNames.push(`display-${display}`);
    }
    if (flexDirection) {
      classNames.push(`flexDirection-${flexDirection}`);
    }
    if (fullHeight) {
      classNames.push("fullHeight");
    }
    if (fullWidth) {
      classNames.push("fullWidth");
    }
    if (justifyContent) {
      classNames.push(`justifyContent-${justifyContent}`);
    }
    if (alignItems) {
      classNames.push(`alignItems-${alignItems}`);
    }
    if (p) {
      classNames.push(`p-${p}`);
    }
    if (pt) {
      classNames.push(`pt-${pt}`);
    }
    if (pb) {
      classNames.push(`pb-${pb}`);
    }
    if (pl) {
      classNames.push(`pl-${pl}`);
    }
    if (pr) {
      classNames.push(`pr-${pr}`);
    }
    if (px) {
      classNames.push(`px-${px}`);
    }
    if (py) {
      classNames.push(`py-${py}`);
    }

    if (m) {
      classNames.push(`m-${m}`);
    }
    if (mx) {
      classNames.push(`mx-${mx}`);
    }
    if (my) {
      classNames.push(`my-${my}`);
    }
    if (mt) {
      classNames.push(`mt-${mt}`);
    }
    if (mb) {
      classNames.push(`mb-${mb}`);
    }
    if (ml) {
      classNames.push(`ml-${ml}`);
    }
    if (mr) {
      classNames.push(`mr-${mr}`);
    }

    return classNames.join(" ");
  }, [
    alignItems,
    backgroundColor,
    className,
    display,
    flexDirection,
    fullHeight,
    fullWidth,
    justifyContent,
    p,
    px,
    py,
    pt,
    pb,
    pl,
    pr,
    m,
    mx,
    my,
    mt,
    mb,
    ml,
    mr,
  ]);
  return (
    <div
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      className={classes}
      style={style}
      id={id}
    >
      {children}
    </div>
  );
}
