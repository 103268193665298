import * as THREE from "three";
import { ReactNode, useMemo } from "react";
import { Positional, usePosition } from "~/gamehook/physics/position";

export interface CameraProps extends Positional {
  children?: ReactNode;
  variant: "orthographic" | "perspective";

  left?: number;
  right?: number;
  top?: number;
  bottom?: number;

  near?: number;
  far?: number;

  fov?: number;
  aspect?: number;

  width?: number;
  height?: number;
}

const DefaultCameraProps: CameraProps = {
  variant: "perspective",
  fov: 75,
};
export function useCreateCamera(
  userProps: CameraProps | undefined
): THREE.Camera {
  const props = userProps ?? DefaultCameraProps;
  const { left, right, top, bottom, near, far, fov, variant } = props;
  const { width, height } = useMemo(() => {
    const width = props.width ?? window.innerWidth;
    const height = props.height ?? window.innerHeight;
    return { width, height };
  }, [props.height, props.width]);
  const aspect = props?.aspect ?? width / height;

  const camera = useMemo(() => {
    if (variant === "perspective") {
      return new THREE.PerspectiveCamera(fov, aspect, near, far);
    } else if (variant === "orthographic") {
      return new THREE.OrthographicCamera(left, right, top, bottom, near, far);
    } else {
      throw new Error("Camera not provided");
    }
  }, [left, right, top, bottom, near, far, fov, aspect, variant]);

  usePosition(camera, props);

  return camera;
}
