import { Box, Text } from "~/ui";
import { IModal, Modal } from "~/ui/modal";

type IHowItWorksModal = Pick<IModal, "open" | "onClose"> & {
  count: number;
  onClose: () => void;
};

export function HowItWorksModal({
  open,
  onClose
}: IHowItWorksModal) {
  return (
    <Modal
      open={open}
      name="How it Works Modal"
      title="How It Works"
      onClose={onClose}
    >
      <Box alignItems="center" style={{ position: 'relative' }}>
        <div
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 0,
            top: '-120px',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '30px'
          }}>✕</div>

        <Text variant="body1">
          <Text variant="body2">Each session creates a random seed that dictates the sequence of shape and color combinations generated as attributes are added. This layer of abstraction balances the impact your responses have on the visual while anonymizing the attributes in the visuals that are generated.</Text>
          <Text variant="body2">Every visual that’s generated is unique; there are billions of possible permutations.</Text>
          <Text variant="body2">Upon submittal, a QR code is generated with resources based on the attributes you selected. Once the session ends, no data is stored and the experience resets.</Text>
        </Text>
      </Box>
    </Modal>
  );
}
