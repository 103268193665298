const EVENT_NAMES = [
  "Experience_Started",
  "Attribute_Selected",
  "Identity_Submitted",
] as const;
type EventName = (typeof EVENT_NAMES)[number];

function event(eventName: EventName, attributes: object) {
  gtag("event", eventName, attributes);
}

export default {
  event,
};
