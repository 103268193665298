import { Box, Button, Text } from "~/ui";
import { useCallback, useMemo } from "react";
import { getAvailableCategories } from "../categories";
import { Category } from "../types";
import { useIdentityContext } from "./hooks";

export function OtherCategoryMenu() {
  const { category, setCategory, setScreenState, setSubCategory } =
    useIdentityContext();
  const otherCategories = useMemo(() => {
    return getAvailableCategories().filter((c) => c.name !== category?.name);
  }, [category]);

  const handleClick = useCallback(
    (c: Category) => {
      setCategory(c);
      setSubCategory(undefined);
      setScreenState("SubCategorySelection");
    },
    [setCategory, setScreenState, setSubCategory]
  );

  return (
    <Box display="flex" className='bottomNav' pb={4}>
      {otherCategories.map((c) => {
        return (
          <Button onClick={() => handleClick(c)} key={c.name} variant="filled">
            <Box key={c.name}>
              <Text variant="body2" style={{ letterSpacing: 6 }}>
                {c.name}
              </Text>
            </Box>
          </Button>
        );
      })}
    </Box>
  );
}
