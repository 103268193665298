import { createBrowserRouter } from "react-router-dom";

import { HomeScreen } from "./screens/Home";
import { TouchScreen } from "./screens/Touch";
import { LargeScreen } from "./screens/Large";
import { buildRoute } from "./config";
import { ResourcesScreen } from "./screens/Resources";
import { ErrorScreen } from "./screens/Error";

export const router = createBrowserRouter([
  {
    path: buildRoute("/"),
    element: <HomeScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: buildRoute("/touch"),
    element: <TouchScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: buildRoute("/large"),
    element: <LargeScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: buildRoute("/resources"),
    element: <ResourcesScreen />,
    errorElement: <ErrorScreen />,
  },
]);
