import * as THREE from "three";

import { PALETTE } from "./colors";

interface Props {
  color_index: number;
  palette: PALETTE;
}

export function useMaterial({ palette, color_index }: Props) {
  const primaryColor = palette[color_index ?? 0];
  return buildMaterial13(primaryColor);
}

function buildMaterial13(primaryColor: number) {
  const search = new URLSearchParams(window.location.search);
  const opacity = parseFloat(search.get("opacity") ?? "0.8");
  const fresnel = parseFloat(search.get("fresnel") ?? "0.6");

  const vertexShader = `
varying vec3 vNormal;
varying vec3 vViewPosition;

void main() {
    vNormal = normalize(normalMatrix * normal);

    // Calculate view position
    vec4 viewPosition = modelViewMatrix * vec4(position, 1.0);
    vViewPosition = -viewPosition.xyz;

    gl_Position = projectionMatrix * viewPosition;
}
`;
  const fragmentShader = `
varying vec3 vNormal;
varying vec3 vViewPosition;

uniform float fresnelPower;
uniform float opacityMultiplier;
uniform vec3 color; // Added color uniform

void main() {
    // Compute the Fresnel effect
    float fresnelTerm = dot(normalize(vNormal), normalize(vViewPosition));
    fresnelTerm = pow(1.0 - fresnelTerm, fresnelPower);

    // Combine the Fresnel effect with the alpha channel
    float finalOpacity = opacityMultiplier * fresnelTerm;

    // Multiply the color with the final opacity
    gl_FragColor = vec4(color, finalOpacity);
}
`;

  // Create a custom material with the shaders
  return new THREE.ShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    uniforms: {
      fresnelPower: { value: fresnel },
      opacityMultiplier: { value: opacity },
      color: { value: new THREE.Color(primaryColor) }, // Set default color to red
    },
    transparent: true,
  });
}
