import { createContext } from "react";

import { Category, SubCategory, UserAttribute, UserIdentity } from "../types";
import { ScreenState } from "./types";

export interface IdentityContextValues {
  identity: UserIdentity;
  setIdentity: (identity: UserIdentity) => void;

  handleEmploymentStatusSelection: (value: "internal" | "external") => void;

  category?: Category;
  subCategory?: SubCategory;
  userAttribute?: UserAttribute;

  setCategory: (category: Category | undefined) => void;
  setSubCategory: (subCategory: SubCategory | undefined) => void;
  setUserAttribute: (attribute: UserAttribute | undefined) => void;

  screenState: ScreenState;
  setScreenState: (screenState: ScreenState) => void;

  hash: number;
  resetHash: () => void;
}
export const IdentityContext = createContext<IdentityContextValues>({
  identity: {
    attributes: [],
    employmentStatus: undefined,
  },
  setIdentity: () => {},
  handleEmploymentStatusSelection: () => {},

  setCategory: () => {},
  setSubCategory: () => {},
  setUserAttribute: () => {},

  screenState: "Intro",
  setScreenState: () => {},

  hash: -1,
  resetHash: () => {},
});
