import { useMemo } from "react";

import { SubCategory } from "~/identities/types";
import { Box } from "~/ui";
import { ScreenContainer } from "~/ui/screen";

import { useIdentityContext } from "./hooks";
import { ComponentSelectionButton } from "~/ui/button";
import { IdentityChart } from "./IdentityChart/IdentityChart";
import { BackButton } from "./BackButton";
import { OtherCategoryMenu } from "./OtherCategoryMenu";

export function SubCategorySelectionScreen() {
  const { category, setSubCategory, setScreenState, identity } =
    useIdentityContext();

  const handleClick = (subCategory: SubCategory) => {
    setSubCategory(subCategory);
    setScreenState("AttributeSelection");
  };

  const subCategories = useMemo(() => {
    const sc = category?.subCategories ?? [];

    return identity.employmentStatus === "internal"
      ? sc.filter((s) => s.externalOnly !== true)
      : sc.filter((s) => s.internalOnly !== true);
  }, [category?.subCategories, identity.employmentStatus]);

  if (!category) {
    return <p>Category not found</p>;
  }

  return (
    <ScreenContainer>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: "100vh" }}
      >
        <Box>
          <BackButton />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ height: "100%" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            mt={4}
            style={{
              flexWrap: "wrap",
              maxHeight: "660px",
            }}
          >
            {subCategories.map((subCategory) => {
              return (
                <Box key={subCategory.name} mb={4}>
                  <ComponentSelectionButton
                    label={subCategory.name}
                    onClick={() => handleClick(subCategory)}
                  />
                </Box>
              );
            })}
          </Box>
          <IdentityChart />
        </Box>
        <Box>
          <OtherCategoryMenu />
        </Box>
      </Box>
    </ScreenContainer>
  );
}
