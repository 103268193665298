import "./styles.scss";
import { getAvailableCategories } from "~/identities/categories";
import { Box, Text } from "~/ui";
import { ScreenContainer } from "~/ui/screen";

import { IdentityChart } from "./IdentityChart/IdentityChart";
import { CategoryButton } from "./CategoryButton";

const categories = getAvailableCategories();

export function CategorySelectionScreen() {
  return (
    <ScreenContainer>
      <Box
        style={{
          width: "70%",
          paddingTop: "32px",
        }}
      >
        <Text
          variant="subtitle"
          style={{
            fontSize: "34px",
          }}
        >
          Choose a category to explore and select as many attributes as you
          would like to add to your identity:
        </Text>
      </Box>

      <Box display="flex">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            pt={4}
            style={{
              margin: "0 auto",
              height: "100%",
            }}
          >
            {categories.map((category) => {
              return (
                <Box key={category.name} my={4}>
                  <CategoryButton category={category} />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box>
          <IdentityChart />
        </Box>
      </Box>
    </ScreenContainer>
  );
}
