import * as THREE from "three";
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { SceneDetails, SceneDetailsContext } from "./context";
import { CameraProps, useCreateCamera } from "~/gamehook/camera";
import { AnimationLoop } from "~/gamehook/animation/providers";

interface IScene {
  antialias?: boolean;
  camera?: CameraProps;
  children: ReactNode;
  id?: string;
}
interface OuterScene extends IScene {
  canvas?: HTMLCanvasElement;
  style?: CSSProperties;
}
interface InnerScene extends IScene {
  canvas: HTMLCanvasElement;
  id: string;
}
const search = new URLSearchParams(window.location.search);
const paddingTop = parseFloat(search.get("paddingTop") ?? "0");
const paddingLeft = parseFloat(search.get("paddingLeft") ?? "0");
const colorString = search.get("background") ?? "353333";
const backgroundColor = parseInt(colorString, 16);
const style: CSSProperties = {
  boxSizing: "border-box",
  paddingTop,
  paddingLeft,
  backgroundColor: `#${colorString}`,
};

export function GameScene(props: InnerScene) {
  const { antialias, children, id } = props;

  const canvas = props.canvas;
  const threeScene = useMemo(() => {
    const scene = new THREE.Scene();
    scene.userData["mixers"] = [];
    scene.background = new THREE.Color(backgroundColor);
    return scene;
  }, []);

  const camera = useCreateCamera(props.camera);

  const renderer = useMemo(() => {
    return new THREE.WebGLRenderer({
      antialias,
      canvas,
      preserveDrawingBuffer: true,
    });
  }, [antialias, canvas]);

  // TODO: move this to lib, and stop writing it multiple times
  const { width, height } = useMemo(() => {
    const width = props.camera?.width ?? window.innerWidth;
    const height = props.camera?.height ?? window.innerHeight;
    return { width, height };
  }, [props.camera?.width, props.camera?.height]);

  useEffect(() => {
    renderer.setSize(width, height);
  }, [width, height, renderer]);

  const clock = useMemo(() => {
    return new THREE.Clock();
  }, []);
  const render = useCallback(() => {
    if (renderer) {
      const delta = clock.getDelta();
      const mixers = threeScene.userData["mixers"] as THREE.AnimationMixer[];
      mixers.forEach((mixer) => {
        if (mixer) {
          mixer.update(delta);
        }
      });
      renderer.render(threeScene, camera);
    }
  }, [clock, renderer, camera, threeScene]);

  const contextValue: SceneDetails = useMemo(() => {
    return {
      canvas,
      id: id,
      render,
      camera,
      threeScene,
    };
  }, [id, canvas, render, camera, threeScene]);

  return (
    <SceneDetailsContext.Provider value={contextValue}>
      <AnimationLoop />
      {children}
    </SceneDetailsContext.Provider>
  );
}

export function Scene(props: OuterScene) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [ready, setReady] = useState(false);
  const id = useMemo(() => {
    return props.id ?? crypto.randomUUID();
  }, [props.id]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 100);
  }, []);

  return (
    <div style={style}>
      <canvas ref={canvasRef} id={id} />
      {ready && canvasRef.current && (
        <GameScene {...props} id={id} canvas={canvasRef.current} />
      )}
    </div>
  );
}
