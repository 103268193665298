import { useMemo } from "react";
import { Box, Text } from "~/ui";
import { Button } from "~/ui/button";
import { IModal, Modal } from "~/ui/modal";

type ISessionExpiringModal = Pick<IModal, "open" | "onClose"> & {
  count: number;
};

export function SessionExpiringModal({
  open,
  onClose,
  count,
}: ISessionExpiringModal) {
  const secondsCopy = useMemo(() => {
    return count > 1 ? `${count} seconds` : "1 second";
  }, [count]);

  return (
    <Modal
      open={open}
      name="Session Expiring Modal"
      title="Are you still there?"
      onClose={onClose}
    >
      <Box alignItems="center">
        <Text variant="body1">
          The experience will reset automatically in {secondsCopy}.
        </Text>
        <Button
          onClick={onClose}
          style={{
            textTransform: "uppercase",
            margin: "auto",
            display: "flex",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: 5,
          }}
        >
          I'm still here
        </Button>
      </Box>
    </Modal>
  );
}
