import { useEffect, useState } from "react";
import { Box, Button, Text } from "~/ui";
import { ScreenContainer } from "~/ui/screen";
import touchIcon from "~/assets/TouchIconV2.png";
import { useIdentityContext } from "./hooks";
import { Header } from "./Header";
import { InitialIdentity } from "../constants";
import analytics from "~/analytics";
import { reportHashToAPI, reportIdentityToAPI } from "~/api";
import { HowItWorksModal } from "./HowItWorksModal";

function TouchIcon() {
  return (
    <img
      style={{ borderRadius: 960, width: 275 }}
      src={touchIcon}
      alt="Touch to begin icon"
    />
  );
}
function StartButton() {
  const context = useIdentityContext();

  const handleClick = () => {
    analytics.event("Experience_Started", {});

    reportHashToAPI(context.hash);

    context.setIdentity(InitialIdentity);
    context.setCategory(undefined);
    context.setSubCategory(undefined);
    context.setUserAttribute(undefined);
    context.setScreenState("InternalExternalSelection");
  };

  // On page load, no one is using the experience
  // Inform the API so that we can show the attraction loop
  useEffect(() => {
    reportHashToAPI(undefined);
    reportIdentityToAPI(null, null);
  }, []);

  return (
    <Box display="flex" justifyContent="center">
      <Button onClick={handleClick} variant="filled">
        <TouchIcon />
        <Text variant="label">Touch to Begin</Text>
      </Button>
    </Box>
  );
}

export function IntroScreen() {
  const [showHowItWorksModal, setShowHowItWorksModal] = useState(false);

  const toggleShowHowItWorksModal = () => {
    setShowHowItWorksModal(!showHowItWorksModal);
  };

  return (
    <ScreenContainer>
      <Header />
      <Box
        style={{
          maxWidth: "80%",
          margin: "auto",
        }}
      >
        <Text
          variant="body1"
          style={{
            fontSize: "20px",
          }}
        >
          Our identities have extraordinary value. They can bring purpose to our
          lives and perspective to each other. We celebrate our people not for
          how well they fit into our culture, but for everything they bring to
          it. That's what makes Deloitte a powerful force for progress.
        </Text>
        <Text
          variant="body1"
          style={{
            fontSize: "20px",
          }}
        >
          In this experience, sharing core facets of your identity will create a
          unique visual representation of your selections and a tailored set of
          resources for you to explore.
        </Text>
        <Text
          variant="body1"
          style={{
            fontSize: "20px",
          }}
        >
          You'll see that with a closer look inside the Green Dot, each of us
          brings something complex, beautiful, and unique.
        </Text>
        <Text
          variant="body1"
          style={{
            fontSize: "20px",
          }}
        >
          Like a kaleidoscope.
        </Text>
      </Box>
      <StartButton />
      <Text variant="caption">
        This experience will dynamically react to your inputs, but it is not
        storing any information.

        <span
          style={{
            marginLeft: '5px',
            borderBottom: '1px solid #000',
            cursor: 'pointer'
          }}
          onClick={toggleShowHowItWorksModal}>
          Learn more
        </span>
      </Text>

      {showHowItWorksModal && (
        <HowItWorksModal open={showHowItWorksModal} onClose={toggleShowHowItWorksModal} />
      )}
    </ScreenContainer>
  );
}
