import * as THREE from "three";

import { useEffect } from "react";
import { XYZ } from "./types";

export interface Movable {
  velocity?: XYZ;
  acceleration?: XYZ;
  rotation?: XYZ;
}

export function useMovement(
  obj: THREE.Object3D | undefined,
  { velocity, acceleration, rotation }: Movable
) {
  useEffect(() => {
    if (obj) {
      obj.userData.velocity = velocity;
    }
    return () => {
      if (obj) {
        obj.userData.velocity = undefined;
      }
    };
  }, [obj, velocity]);

  useEffect(() => {
    if (obj) {
      obj.userData.acceleration = acceleration;
    }
    return () => {
      if (obj) {
        obj.userData.acceleration = undefined;
      }
    };
  }, [obj, obj?.userData, acceleration]);

  useEffect(() => {
    if (obj) {
      obj.userData.rotation = rotation;
    }
    return () => {
      if (obj) {
        obj.userData.rotation = undefined;
      }
    };
  }, [obj, obj?.userData, rotation]);
}

type MovableObject = {
  userData: Movable;
} & THREE.Object3D;
export function moveSceneObjects(scene: THREE.Scene) {
  scene.traverse((child: MovableObject) => {
    if (child.userData.velocity) {
      child.position.x += child.userData.velocity[0];
      child.position.y += child.userData.velocity[1];
      child.position.z += child.userData.velocity[2];
    }

    if (child.userData.acceleration) {
      if (!child.userData.velocity) {
        child.userData.velocity = [0, 0, 0];
      }
      child.userData.velocity[0] += child.userData.acceleration[0];
      child.userData.velocity[1] += child.userData.acceleration[1];
      child.userData.velocity[2] += child.userData.acceleration[2];
    }

    if (child.userData.rotation) {
      child.rotation.x += child.userData.rotation[0];
      child.rotation.y += child.userData.rotation[1];
      child.rotation.z += child.userData.rotation[2];
    }
  });
}

export function moveObject(
  obj: THREE.Object3D,
  movement: XYZ,
  duration: number
) {
  const start = Date.now();
  let current = Date.now();
  let frame: number;

  function animate() {
    const tick = Date.now();
    const elapsed = tick - start;
    const progress = elapsed / duration;
    const delta = (tick - current) / duration;
    current = tick;

    if (progress >= 1) {
      obj.userData.moving = false;
      return;
    } else {
      frame = requestAnimationFrame(animate);
      obj.position.x += movement[0] * delta;
      obj.position.y += movement[1] * delta;
      obj.position.z += movement[2] * delta;
    }
  }
  animate();
  return () => {
    if (frame) {
      cancelAnimationFrame(frame);
    }
  };
}

export function moveObjectTo(
  obj: THREE.Object3D,
  destination: XYZ,
  duration: number
) {
  const movement: XYZ = [
    destination[0] - obj.position.x,
    destination[1] - obj.position.y,
    destination[2] - obj.position.z,
  ];
  if (!obj.userData.moving) {
    obj.userData.moving = true;
    moveObject(obj, movement, duration);
  }
}
