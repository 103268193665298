import { useCallback, useContext, useEffect } from "react";

import { SceneDetailsContext, useThreeScene } from "~/gamehook/scene/context";
import { moveSceneObjects } from "~/gamehook/physics/movement";

export function AnimationLoop() {
  const { render } = useContext(SceneDetailsContext);
  const scene = useThreeScene();

  const animate = useCallback(() => {
    const frame = requestAnimationFrame(animate);

    // Move objects
    moveSceneObjects(scene);

    // Render the scene
    render();
    return () => {
      cancelAnimationFrame(frame);
    };
  }, [render, scene]);

  useEffect(() => {
    animate();
  }, [animate]);

  return <></>;
}
