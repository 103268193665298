import { Shape } from "~/gamehook";

export function GreenCircle() {
  return (
    <>
      <Shape
        position={[0, 0, -15]}
        scale={[10, 10, 10]}
        geometry={{
          type: "sphere",
          heightSegments: 128,
        }}
        material={{
          type: "basic",
          color: 0x86bc25,
        }}
      />
    </>
  );
}
