import { useMemo } from "react";
import { Model, XYZ } from "~/gamehook";
import { AnimationSettings } from "~/gamehook/models";
import { getModelAndZ } from "./models";
import { useMaterial } from "~/materials";
import { PALETTE } from "~/colors";

interface Props {
  color_index: number;
  model_index: number;
  palette: PALETTE;
  depth: number;
}

const animation: AnimationSettings = {
  name: "animation_0",
  loopMode: "once",
};

export function Layer({ color_index, model_index, palette, depth }: Props) {
  const [path, z] = useMemo(() => {
    return getModelAndZ({ index: model_index, depth });
  }, [depth, model_index]);

  const material2 = useMaterial({ palette, color_index });

  const rotation = useMemo<XYZ>(() => {
    return depth % 2 === 0 ? [0, 0, 0.002] : [0, 0, -0.002];
  }, [depth]);

  return (
    <Model
      path={path}
      position={[0, 0, z]}
      material={material2}
      rotation={rotation}
      animation={depth !== -1 ? animation : undefined}
    />
  );
}
