import { DisplayProperties } from "~/api";

export function encodeDisplayProperties(properties: DisplayProperties): string {
  const json = JSON.stringify(properties);
  return btoa(json);
}

export function decodeDisplayProperties(value: string): DisplayProperties {
  const json = atob(value);
  return JSON.parse(json);
}
