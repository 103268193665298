import { Box, Text } from "~/ui";

import SliderControl from "~/assets/SliderControl.svg";
import { useCallback, useMemo, useRef } from "react";

interface Props {
  color: string;
  onChange: (value: number) => void;
  value: number;
}

const STEP_COUNT = 10;
const SLIDER_ID = "slider_id";

export function Slider({ color, value, onChange }: Props) {
  const isDragging = useRef(false);

  const handleGlobalDragEnd = useCallback(() => {
    isDragging.current = false;
    document.removeEventListener("mouseup", handleGlobalDragEnd);
    document.removeEventListener("touchend", handleGlobalDragEnd);
  }, []);

  const handleDragStart = useCallback(() => {
    isDragging.current = true;
    document.addEventListener("mouseup", handleGlobalDragEnd);
    document.addEventListener("touchend", handleGlobalDragEnd);
  }, [handleGlobalDragEnd]);

  const handleDragMove = useCallback(
    (clientX: number) => {
      if (!isDragging.current) {
        return;
      }
      const sliderEl = document.getElementById(SLIDER_ID);
      if (!sliderEl) {
        return;
      }
      const sliderBox = sliderEl.getBoundingClientRect();
      const newValue = ((clientX - sliderBox.left) / sliderBox.width) * 100;
      const nearestStep = Math.round(newValue / STEP_COUNT) * STEP_COUNT;
      if (nearestStep > 100) return 100;
      if (nearestStep < 0) return 0;
      onChange(nearestStep);
    },
    [onChange]
  );

  return (
    <Box
      id="sliderContainer"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        id={SLIDER_ID}
        style={{
          height: 24,
          width: "90%",
          borderRadius: 40,
          background: `linear-gradient(90deg, ${color}05 0%, ${color} 100%)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          onMouseMove={(e) => handleDragMove(e.clientX)}
          onMouseDown={handleDragStart}
          onTouchMove={(e) => handleDragMove(e.touches[0].clientX)}
          onTouchStart={handleDragStart}
          style={{
            zIndex: 2,
            width: "1000px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={SliderControl}
            alt="Control for intensity slider"
            style={{
              position: "relative",
              height: 64,
              left: `calc(${value}%)`,
              transform: "translate(-50%, 0%)",
              width: 64,
            }}
            draggable={false}
            onDragStart={() => false}
          />
        </div>
      </Box>
    </Box>
  );
}
