export function Link({ label, to }: { label: string; to: string }) {
  return (
    <a className="uiLink" href={to}>
      {label}
    </a>
  );
}

export function ResourceLink({ label, to }: { label: string; to: string }) {
  return (
    <a className="resourceLink" href={to}>
      {label}
    </a>
  );
}
