import { useMemo } from "react";
import * as THREE from "three";

import { Shapeable } from "./types";

const DefaultGeometry = new THREE.BoxGeometry(1, 1, 1);
export function useGeometry(props: Shapeable) {
  const stringifiedParams = useMemo(() => {
    return props.geometry ? JSON.stringify(props.geometry) : undefined;
  }, [props]);

  return useMemo(() => {
    if (!stringifiedParams) {
      return DefaultGeometry;
    }
    const params: Shapeable["geometry"] = JSON.parse(stringifiedParams);
    if (!params) {
      return DefaultGeometry;
    }
    if (props.geometry instanceof THREE.BufferGeometry) {
      return props.geometry;
    }
    if (params instanceof THREE.BufferGeometry) {
      throw new Error("Params shouldn't be buffer geometry");
    }

    switch (params.type) {
      case "box":
        return new THREE.BoxGeometry(
          params.width,
          params.height,
          params.depth,
          params.widthSegments,
          params.heightSegments,
          params.depthSegments
        );

      case "cylinder":
        return new THREE.CylinderGeometry(
          params.radiusTop,
          params.radiusBottom,
          params.height,
          params.radialSegments,
          params.heightSegments,
          params.openEnded,
          params.thetaStart,
          params.thetaLength
        );
      case "sphere":
        return new THREE.SphereGeometry(
          params.radius,
          params.widthSegments,
          params.heightSegments,
          params.phiStart,
          params.phiLength,
          params.thetaStart,
          params.thetaLength
        );

      default:
        return DefaultGeometry;
    }
  }, [props.geometry, stringifiedParams]);
}
