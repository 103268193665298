import { useEffect, useMemo, useState } from "react";
import { UserIdentity } from "./identities/types";

/** Send the current user's hash to the API
 *
 * A user's hash is generated on session start, and ensures that all other
 * outputs that they receive look different from someone else's. However, we
 * need the experience they see on the screen to also match the experience
 * they see when they download their zoom background. So this hash is saved
 * to client-side state, used in generation of the QR code for users to scan,
 * and also passed along to the API.
 *
 * Meanwhile, the large screen polls the API for what hash it should be using
 * in generating its deterministic configuration of shapes and colors.
 **/
const clientKey = "90EF2287A5B828FDBE5AED8C021571E3A5F2824F0D1A66D90EB4B3F737625EA7";
const baseUrl = import.meta.env.VITE_API_URI || '/api';

export function reportHashToAPI(hash: number | undefined) {
  const url = `${baseUrl}/hash/set/`;
  const payload = {
    clientKey,
    hash,
  };
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

export async function getCurrentDisplayHashFromAPI(): Promise<number> {
  try {
    const url = `${baseUrl}/hash/get/`;
    const response = await fetch(url);
    const data = await response.json();
    return data.user_hash;
  } catch (err) {
    return -1;
  }
}

export interface LayerProps {
  color_index: number;
  model_index: number;
  intensity: number;
}
export interface DisplayProperties {
  data: {
    palette_index: number;
    layers: LayerProps[] | null;
  } | null;
  user_hash: number | null;
}

export function reportIdentityToAPI(
  identity: UserIdentity | null,
  hash: number | null
) {
  const url = `${baseUrl}/identity/set/`;
  const payload = {
    identity,
    hash,
  };
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

export async function fetchDisplayProperties(): Promise<string | null> {
  try {
    const url = `${baseUrl}/identity/display/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const text = await response.text();
    return text;
  } catch (err) {
    return null;
  }
}

const timeBetweenFetches = 1000; // TODO: fetch less frequently when inactive
export function useDisplayProperties(
  pollForUpdates: boolean | undefined = true
) {
  const [propertyString, setPropertyString] = useState<string | null>(null);

  useEffect(() => {
    fetchDisplayProperties().then(setPropertyString);
    let interval: NodeJS.Timeout | undefined = undefined;
    if (pollForUpdates) {
      interval = setInterval(() => {
        fetchDisplayProperties().then((str) => {
          setPropertyString(str);
        });
      }, timeBetweenFetches);
    } else {
      if (interval) {
        clearInterval(interval);
      }
      interval = undefined;
    }
    return () => {
      clearInterval(interval);
    };
  }, [pollForUpdates]);

  const properties = useMemo<DisplayProperties | null>(() => {
    if (!propertyString) return null;
    const data = JSON.parse(propertyString);
    return data;
  }, [propertyString]);

  return properties;
}

export function useKaleidoscopeState(
  pollForUpdates: boolean | undefined = true
): "attraction" | "green" | "layers" {
  const properties = useDisplayProperties(pollForUpdates);
  if (!properties?.user_hash) {
    return "attraction";
  }
  if (!properties.data?.layers) {
    return "green";
  }
  if (properties.data.layers.length < 1) {
    return "green";
  }
  return "layers";
}
