import * as THREE from "three";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { hasMaterial, useMaterial } from "~/gamehook/materials/index";
import { Interactable, useInteraction } from "gamehook/interactions";
import { HierarchyContext, useParent } from "gamehook/hierarchy";
import { Movable, useMovement } from "gamehook/physics/movement";
import { Positional, usePosition } from "gamehook/physics/position";
import { SceneDetailsContext, useThreeScene } from "gamehook/scene/context";
import { Materializable } from "../materials";

export interface AnimationSettings {
  name: string;
  keyframe?: number;
  loopMode?: "loop" | "once" | "pingPong";
  repetitions?: number;
}
interface Animateable {
  animation?: AnimationSettings;
}
interface IModel
  extends Animateable,
    Interactable,
    Movable,
    Positional,
    Materializable {
  children?: ReactNode;
  path: string;
  id?: string;
  startingZ?: number;
}

export function Model({ children, path, ...props }: IModel) {
  const scene = useContext(SceneDetailsContext);
  const [loadedModel, setLoadedModel] = useState<GLTF | undefined>(undefined);

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(
      path,
      (gltf: GLTF) => {
        // FileMap[path] = gltf;
        setLoadedModel(gltf);
      },
      undefined,
      function (error) {
        console.error("Failed to load animation", error);
      }
    );
    return () => {
      setLoadedModel(undefined);
    };
  }, [path]);

  const parent = useParent();
  useEffect(() => {
    if (loadedModel) {
      if (parent) {
        parent.add(loadedModel.scene);
      } else {
        scene.threeScene.add(loadedModel.scene);
      }
    }
    return () => {
      if (loadedModel) {
        loadedModel.scene.removeFromParent();
        scene.threeScene.remove(loadedModel.scene);
      }
    };
  }, [loadedModel, scene.threeScene, parent]);

  // It's important that each model have their own mixer. Otherwise, identical loaded models will
  // share animations
  const mixer = useMemo(() => {
    if (loadedModel) {
      return new THREE.AnimationMixer(loadedModel?.scene);
    }
  }, [loadedModel]);

  useAnimation(loadedModel, props, mixer);
  useModelMaterial(loadedModel?.scene, props);
  useInteraction(loadedModel?.scene, props);
  useMovement(loadedModel?.scene, props);
  usePosition(loadedModel?.scene, props);
  if (!loadedModel) {
    return null;
  }

  return (
    <HierarchyContext.Provider value={{ parent: loadedModel }}>
      {children}
    </HierarchyContext.Provider>
  );
}

function useModelMaterial(
  model: THREE.Object3D | undefined,
  props: Materializable
) {
  const threeMaterial = useMaterial(props);
  useEffect(() => {
    if (threeMaterial) {
      model?.traverse((child) => {
        if (hasMaterial(child)) {
          child.material = threeMaterial;
        }
      });
    }
    return () => {
      if (threeMaterial) {
        threeMaterial.dispose();
      }
    };
  }, [model, threeMaterial]);
}

function useAnimation(
  loadedModel: GLTF | undefined,
  props: Animateable,
  mixer: THREE.AnimationMixer | undefined
) {
  const { animation } = props;
  const threeScene = useThreeScene();

  const clip = useMemo(() => {
    return loadedModel && animation
      ? THREE.AnimationClip.findByName(
          loadedModel.animations,
          animation.name
        ).clone()
      : undefined;
  }, [animation, loadedModel]);

  useEffect(() => {
    threeScene.userData["mixers"].push(mixer);
    return () => {
      const mixers = threeScene.userData["mixers"];
      threeScene.userData["mixers"] = mixers.filter(
        (m: THREE.AnimationMixer) => m !== mixer
      );
    };
  }, [mixer, threeScene]);

  const action = useMemo(() => {
    return clip ? mixer.clipAction(clip) : undefined;
  }, [clip, mixer]);

  useEffect(() => {
    if (action && animation) {
      const loopMode = animation.loopMode
        ? {
            pingPong: THREE.LoopPingPong,
            once: THREE.LoopOnce,
            loop: THREE.LoopRepeat,
          }[animation.loopMode]
        : THREE.LoopRepeat;
      const repetitions = animation.repetitions ?? Infinity;
      action.setLoop(loopMode, repetitions);
      action.clampWhenFinished = true;
      action.play();
    }
    return () => {
      if (action) {
        action.stop();
      }
    };
  }, [action, animation]);
}
