import { RouterProvider } from "react-router-dom";
import { useRefresh } from "./config";
import { router } from "./router";

function App() {
  useRefresh();
  return (
    <div className="App" onContextMenu={(e) => e.preventDefault()}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
