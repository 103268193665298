import { useCallback, useEffect, useState } from "react";
import seedrandom from "seedrandom";

import { getCurrentDisplayHashFromAPI, reportHashToAPI } from "./api";
import { useIdentityContext } from "./identities/Chooser/hooks";

export function getRandomItemWithHash<T>(hash: string, items: T[]): T {
  // Create a seeded random number generator using the hash
  const rng = seedrandom(hash);

  // Generate a random index based on the length of the items array
  const randomIndex = Math.floor(rng() * items.length);

  // Return the item at the generated index
  return items[randomIndex];
}

export function generateHash(): number {
  const str = crypto.randomUUID();
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function getUrlValue(key: string) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const value = urlParams.get(key);
  return value;
}

export function useHash(): number | null {
  const context = useIdentityContext();
  const urlHash = getUrlValue("hash");

  const [hash, setHash] = useState<number | null>(null);

  const pollForHash = useCallback(() => {
    if (urlHash) {
      setHash(parseInt(urlHash));
    } else {
      getCurrentDisplayHashFromAPI().then((hash) => {
        if (hash) {
          setHash(hash);
        } else {
          // Server restarted, tell the server about the current hash
          reportHashToAPI(context.hash);
          setHash(hash);
        }
      });
    }
  }, [urlHash]);

  // Check for new hashes every 2 seconds
  useEffect(() => {
    pollForHash();
    const interval = setInterval(pollForHash, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [pollForHash]);

  return hash;
}
