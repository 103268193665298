import * as THREE from "three";
import { ReactNode, useEffect, useMemo } from "react";

import { useThreeScene } from "~/gamehook/scene/context";
import { useMaterial, Materializable } from "~/gamehook/materials";
import { Positional, usePosition } from "~/gamehook/physics/position";
import { Nameable, useName } from "~/gamehook/taxonomy";
import { Movable, useMovement } from "~/gamehook/physics/movement";
import { HierarchyContext, useParent } from "~/gamehook/hierarchy";
import { Interactable, useInteraction } from "~/gamehook/interactions";
import { useGeometry } from "./hooks";
import { Shapeable } from "./types";

interface BaseShape
  extends Interactable,
    Materializable,
    Movable,
    Nameable,
    Positional,
    Shapeable {
  children?: ReactNode;
}

export function Shape({ children, ...props }: BaseShape) {
  const scene = useThreeScene();

  const builtGeometry = useGeometry(props);
  const geometry =
    props.geometry instanceof THREE.BufferGeometry
      ? props.geometry
      : builtGeometry;
  const material = useMaterial(props);
  const mesh = useMemo(() => {
    return new THREE.Mesh(geometry, material);
  }, [geometry, material]);

  useInteraction(mesh, props);
  useName(mesh, props);
  usePosition(mesh, props);
  useMovement(mesh, props);
  const parent = useParent();

  useEffect(() => {
    if (parent) {
      parent.add(mesh);
    } else {
      scene.add(mesh);
    }
    return () => {
      mesh.removeFromParent();
      scene.remove(mesh);
      if (geometry) {
        geometry.dispose();
      }
      material.dispose();
    };
  }, [geometry, scene, mesh, material, parent]);

  return (
    <HierarchyContext.Provider value={{ parent: mesh }}>
      {children}
    </HierarchyContext.Provider>
  );
}
