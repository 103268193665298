import { ReactNode, useMemo } from "react";

import "./styles.scss";

type TextVariant =
  | "subtitle"
  | "title"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "body1"
  | "body2"
  | "label";

interface IText {
  children: ReactNode;
  color?: "dark" | "light" | "error";
  textAlign?: "center" | "left";
  variant?: TextVariant;
  className?: string;
  style?: object;
  id?: string;
}

export function Text({
  className,
  children,
  color,
  textAlign,
  variant = "h1",
  style,
  id,
}: IText) {
  const classes = useMemo(() => {
    const classes = ["uiText", variant, color];
    if (textAlign) {
      classes.push(`textAlign-${textAlign}`);
    }
    if (className) {
      classes.push(className);
    }
    return classes.join(" ");
  }, [className, color, textAlign, variant]);

  switch (variant) {
    case "title":
      return (
        <h1 className={classes} style={style} id={id}>
          {children}
        </h1>
      );
    case "subtitle":
      return (
        <span className={classes} style={style} id={id}>
          {children}
        </span>
      );
    case "h1":
      return (
        <h1 className={classes} style={style} id={id}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={classes} style={style} id={id}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 className={classes} style={style} id={id}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 className={classes} style={style} id={id}>
          {children}
        </h4>
      );
    case "body1":
      return (
        <p className={classes} style={style} id={id}>
          {children}
        </p>
      );
    case "body2":
      return (
        <p className={classes} style={style} id={id}>
          {children}
        </p>
      );
    case "label":
      return (
        <p className={classes} style={style} id={id}>
          {children}
        </p>
      );
    case "caption":
      return (
        <p className={classes} style={style} id={id}>
          {children}
        </p>
      );
    default:
      return (
        <span style={style} id={id}>
          {children}
        </span>
      );
  }
}
