const Colors = [
  "#00ABAB",
  "#EFC100",
  "#0076A8",
  "#9E2E48",
  "#C4D600",
  "#43B02A",
  "#005587",
  "#552094",
  "#C8A100",
  "#00A3E0",
  "#691A2D",
  "#E47A0C",
  "#BB80FF",
  "#046235",
  "#A0DCFF",
  "#2C5234",
  "#9AA800",
  "#00626B",
  "#62B5E5",
  "#2C5234",
];

export const getColor = (index: number): string =>
  Colors[index % Colors.length];
